import React,{useState, useEffect} from 'react'
import './AllServices.css';

import AnglesDown from '../../assets/about/angles_down3.png';
import Reach from '../ReachOut/Reach';
import { Helmet } from 'react-helmet-async';
import cyberconsult from '../../assets/service_about/cas1.png'
import CyberSecure from '../../assets/service_about/cybersec.png'
import CyberManage from '../../assets/service_about/cms2.png'
import SecInfra from '../../assets/service_about/secinfra.png'
import SysAuto from '../../assets/service_about/sysauto.png'
import IntelNet from '../../assets/service_about/intelnet.png'
import InterMedia from '../../assets/service_about/intermedia.png'
import Cab from '../../assets/service_about/cab.png'
import consult from '../../assets/service_about/conversation.png'
import DataCen from '../../assets/service_about/datacenter.png'
import AppMod from '../../assets/service_about/appmod.png'
import DataManage from '../../assets/service_about/datamanage.png'
import HyperAuto from '../../assets/service_about/hyperauto.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faPeopleRoof, faHandshake, faShield, faGears, faNetworkWired, faSliders, faDiagramProject, faFileShield, faCode, faServer, faBrain, faListCheck} from '@fortawesome/free-solid-svg-icons';
import { faBusinessTime, faChartPie, faTruckFast, fa1, fa2, fa3, fa4, fa5, fa6 } from '@fortawesome/free-solid-svg-icons';

export default function AllServices() {

    // const faBusinessIcon = <FontAwesomeIcon icon={faBusinessTime} />
    // const faChartPieIcon = <FontAwesomeIcon icon={faChartPie} />
    // const faTruckFastIcon = <FontAwesomeIcon icon={faTruckFast} />
    // const faOneIcon = <FontAwesomeIcon icon={fa1} />
    // const faTwoIcon = <FontAwesomeIcon icon={fa2} />
    // const faThreeIcon = <FontAwesomeIcon icon={fa3} />
    // const faFourIcon = <FontAwesomeIcon icon={fa4} />
    // const faFiveIcon = <FontAwesomeIcon icon={fa5} />
    // const faSixIcon = <FontAwesomeIcon icon={fa6} />
    const faRobotIcon = <FontAwesomeIcon icon={faRobot} />
    const faPeopleRoofIcon = <FontAwesomeIcon icon={faPeopleRoof} />
    const faHandshakeIcon = <FontAwesomeIcon icon={faHandshake} />

    const faShieldIcon = <FontAwesomeIcon icon={faShield} />
    const faGearsIcon = <FontAwesomeIcon icon={faGears} />
    const faNetworkWiredIcon = <FontAwesomeIcon icon={faNetworkWired} />
    const faSlidersIcon = <FontAwesomeIcon icon={faSliders} />
    const faDiagramProjectIcon = <FontAwesomeIcon icon={faDiagramProject} />
    const faFileShieldIcon = <FontAwesomeIcon icon={faFileShield} />

    const faCodeIcon = <FontAwesomeIcon icon={faCode} />
    const faServerIcon = <FontAwesomeIcon icon={faServer} />
    const faBrainIcon = <FontAwesomeIcon icon={faBrain} />
    const faListCheckIcon = <FontAwesomeIcon icon={faListCheck} />
    /*Smaple */

    const listenToScrolls = () => {
        let heightToHidden = 5;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    
        (windowScroll > heightToHidden) ? setIsVisible(false) : setIsVisible(true);
    }
    
    useEffect(()=>{
        window.addEventListener("scroll", listenToScrolls)
    })
    
    const [isVisible, setIsVisible] = useState(true);

    /*Smaple */
  
  return (
    <>
    <Helmet>
        <title>SIBR Solutions</title>
      
        <meta name="description" content="At SIBR, we provide services in Application Modernization, data management platforms, hyperautomation, consulting and advisory services, Cybersecurity Services, Cybersecurity Managed Services, Security Infrastructure, System Automation, Intelligent Networking, Interactive Media Solutions, Infrastructure Cabling and Data Facility Planning"/>
        <link rel='canonical' href=''/>
    </Helmet>
    <section id='allservices_container'>
    <div className="service_banner">
       
    </div>
    <div className="container">
            <div className='service_outer'>
          
                <h1 className='service_title'>
                <span>THE SOLUTION <br />
                    YOU NEED</span>
                </h1>
               
              

                
            </div>
            
        </div>
        
        
        <br />
        <br />
        <br />
        
       
        
        
    </section>
    {isVisible && (
        <div className="ha_down_arrow">
            
            <img src={AnglesDown} alt='Alt'></img>
   
        </div>
      )}
    <section id='serviceAllCAS_container'>
        <div className='container'>
            <div className='serviceAllCAS_wrapper'>
                <div className='serviceAllCAS_col'>
                <h3>Cybersecurity</h3>
                <p>Cybersecurity services play a pivotal role in safeguarding organizations against the ever-evolving landscape of cyber threats. These services encompass a comprehensive range of measures designed to protect sensitive information, networks, and systems from unauthorized access, data breaches, and malicious activities. Cybersecurity professionals employ advanced technologies, threat intelligence, and proactive strategies to identify vulnerabilities and fortify defenses. Services often include implementing robust firewalls, encryption protocols, and intrusion detection systems, along with ongoing monitoring and incident response capabilities. As the digital landscape continues to expand, cybersecurity services are essential for organizations to navigate the complex challenges of the cyber realm, ensuring the confidentiality, integrity, and availability of critical assets.</p>

                <div className='serviceall_cyber_wrapper'>
                <div className='serviceall_cyber_box'>
                    <div className='serviceall_cyber_icon'>
                        {faHandshakeIcon}
                        

                    </div>
                    <div className='about_content'>
                        <h5>Consulting & Advisory Services</h5>
                        <br/>
                        <a className='serviceall_cyber_btn' href='/solutions/consulting-and-advisory-services'>Learn More</a>
                        
                    </div>
                </div>

                <div className='serviceall_cyber_box'>
                    <div className='serviceall_cyber_icon blue_icon'>
                        {faRobotIcon}

                    </div>
                    <div className='about_content'>
                        <h5>Cybersecurity <br/> Services</h5>
                        <br/>
                        <a className='serviceall_cyber_btn' href='/solutions/cybersecurity-services'>Learn More</a>
                        
                    </div>
                </div>

                <div className='serviceall_cyber_box'>
                    <div className='serviceall_cyber_icon green_icon'>
                        {faPeopleRoofIcon}

                    </div>
                    <div className='about_content'>
                        <h5>Cybersecurity Managed Services</h5>
                        <br/>
                        <a className='serviceall_cyber_btn' href='/solutions/cybersecurity-managed-services'>Learn More</a>
                        
                    </div>
                </div>
                

              
                

            </div>
                    {/* <h3>Consulting and Advisory Services</h3>
                    <ul>
                    <p><li>GRC Consulting Services, focusing on Local (NCA, SAMA) and International Frameworks (ISO, GDPR, NIST)</li></p>
                    <br/>
                    <p><li>Data Classification Consulting Services</li></p>
                    <br/>
                    <p><li>Business Continuity Plan and Business Resiliency</li></p>
                    <br/>
                    <p><li>Security Awareness Consulting Services</li></p>
                    <br/>
                    <p><li>Cybersecurity Strategies and Program Development</li></p>
                    <br/>
                    <p><li>Identity and Access Management Consulting Services</li></p>
                    <br/>
                    <p><li>Cybersecurity Organization Building Consulting Services</li></p>
                    <br/>
                    <p><li>Security Operations Center (SOC) Consulting</li></p>
                    <br/>
                    <p><li>Cybersecurity Training and Education Program</li></p>
                    <br/>
                    <p><li>Threat Intelligence and Incident Response Consulting and Management vCISO</li></p>
                    </ul> */}
                  
                </div>
                <div className='about_col'>
                 
                </div>
            </div>
        </div>
    </section>

    <br/>
    <br/>
    <hr className='about_line'/>

    <section id='serviceAllCS_container'>
        <div className='container'>
            <div className='serviceAllCS_wrapper'>
                <div className='serviceAllCS_col'>
                <h3>Information Communication Technology</h3>
                <p>Information Communication Technology (ICT) services form the backbone of modern business operations, encompassing a diverse range of solutions aimed at optimizing communication, collaboration, and technological infrastructure. ICT services cover areas such as network design and management, cloud computing, cybersecurity, software development, and IT support. These services empower organizations to enhance operational efficiency, streamline processes, and stay competitive in an increasingly digital world. From developing custom software applications to managing robust communication networks, ICT services play a crucial role in enabling seamless connectivity, data management, and technological innovation. As businesses continue to evolve, ICT services provide the technological foundation necessary for growth, adaptability, and sustained success in today's dynamic and interconnected global landscape.</p>

                <div className='serviceall_ict_wrapper'>
                <div className='serviceall_ict_box'>
                <br/>
           
                    <div className='serviceall_ict_icon'>
                        {faShieldIcon}

                    </div>
                    <div className='about_content'>
                        <h5>Security Infrastructure</h5>
                        <br/>
                        <a className='serviceall_ict_btn' href='/solutions/security-infrastructure'>Learn More</a>
                        
                    </div>
                </div>

                <div className='serviceall_ict_box'>
                <br/>
               
                    <div className='serviceall_ict_icon blue_icon'>
                        {faGearsIcon}

                    </div>
                    <div className='about_content'>
                        <h5>System Automation</h5>
                        <br/>
                        <a className='serviceall_ict_btn' href='/solutions/system-automation'>Learn More</a>
                        
                    </div>
                </div>

                <div className='serviceall_ict_box'>
                <br/>
         
                    <div className='serviceall_ict_icon green_icon'>
                        {faNetworkWiredIcon}

                    </div>
                    <div className='about_content'>
                        <h5>Intelligent Networking</h5>
                        <br/>
                        <a className='serviceall_ict_btn' href='/solutions/intelligent-networking'>Learn More</a>
                        
                    </div>
                </div>
                
         
                <div className='serviceall_ict_box'>
                    <br/>
                    <br/>
               
                    <div className='serviceall_ict_icon blue_icon'>
                        {faSlidersIcon}

                    </div>
                    <div className='about_content'>
                        <h5>Interactive Media Solutions</h5>
                        <br/>
                        <a className='serviceall_ict_btn' href='/solutions/interactive-media-solutions'>Learn More</a>
                        
                    </div>
                </div>

                <div className='serviceall_ict_box'>
                <br/>
                <br/>
                    <div className='serviceall_ict_icon green_icon'>
                        {faDiagramProjectIcon}

                    </div>
                    <div className='about_content'>
                        <h5>Infrastructure Cabling</h5>
                        <br/>
                        <a className='serviceall_ict_btn' href='/solutions/infrastructure-cabling'>Learn More</a>
                        
                    </div>
                </div>

                <div className='serviceall_ict_box'>
                <br/>
                <br/>
                    <div className='serviceall_ict_icon'>
                        {faFileShieldIcon}

                    </div>
                    <div className='about_content'>
                        <h5>Data Facility Planning</h5>
                        <br/>
                        <a className='serviceall_ict_btn' href='/solutions/data-facility-planning'>Learn More</a>
                        
                    </div>

                    
                </div>
                
                <div className='serviceall_ict_box'>
                <br/>
                <br/>
                <br/>
                    <div className='serviceall_ict_icon green_icon'>
                        {faListCheckIcon}

                    </div>
                    <div className='about_content'>
                        <h5>ICT Managed Services</h5>
                        <br/>
                        <a className='serviceall_ict_btn' href='/solutions/ICT-managed-services'>Learn More</a>
                        
                    </div>

                    
                </div>

                
                

              
                

            </div>
                    {/* <h3>Cybersecurity Services</h3>
                    <ul>
                    <p><li>Incident Response Services</li></p>
                    <br/>
                    <p><li>Compromise Assessments</li></p>
                    <br/>
                    <p><li>Vulnerability Assessment Services</li></p>
                    <br/>
                    <p><li>Penetration Testing Services</li></p>
                    <br/>
                    <p><li>Red Teaming Exercises</li></p>
                    <br/>
                    <p><li>Source Code Reviews</li></p>
                    <br/>
                    <p><li>Digital Forensics (DF) Investigation Services</li></p>
                    <br/>
                    <p><li>Malware Analysis Services</li></p>
                    <br/>
                    <p><li>Cloud Security Assessment Services</li></p>
                    <br/>
                    <p><li>Ransomware Protection and Recovery Services</li></p>
                    <br/>
                    <p><li>Threat Detection and Detection Services</li></p>
                    </ul> */}
                    
                </div>
                <div className='about_col'>
                    
                </div>
            </div>
        </div>
    </section>
    <br/>
    <br/>
    <hr className='about_line'/>

    <section id='serviceAllCMS_container'>
        <div className='container'>
            <div className='serviceAllCMS_wrapper'>
                <div className='serviceAllCMS_col'>
                <h3>Digital Innovation</h3>
                <p>Digital Innovation services represent the cutting edge of transformative solutions that leverage emerging technologies to drive change and create value for businesses. These services encompass a spectrum of initiatives, including the development of innovative digital products, the integration of artificial intelligence, machine learning, and data analytics, as well as the adoption of disruptive technologies like blockchain and augmented reality. Digital Innovation services focus on enhancing customer experiences, optimizing internal processes, and fostering a culture of continuous improvement. By embracing the latest advancements, businesses can unlock new opportunities, stay ahead of the competition, and navigate the complexities of the digital era. Digital Innovation services are integral to reshaping industries, driving efficiency, and fostering a culture of agility and adaptability in the face of evolving market dynamics.</p>

                <div className='serviceall_di_wrapper'>
                <div className='serviceall_di_box'>
                <br/>
                    <div className='serviceall_di_icon'>
                        {faCodeIcon}

                    </div>
                    <div className='about_content'>
                        <h5>App Modernization</h5>
                        <br/>
                        <a className='serviceall_di_btn' href='/solutions/appmodernization'>Learn More</a>
                        
                    </div>
                </div>

                <div className='serviceall_di_box'>
                    <br/>
                 
                    <div className='serviceall_di_icon blue_icon'>
                        {faServerIcon}

                    </div>
                    <div className='about_content'>
                        <h5>Data Management Platforms</h5>
                        <br/>
                        <a className='serviceall_di_btn' href='/solutions/data-management-platforms'>Learn More</a>
                        
                    </div>
                </div>

                <div className='serviceall_di_box'>
                <br/>
          
                    <div className='serviceall_di_icon green_icon'>
                        {faBrainIcon}

                    </div>
                    <div className='about_content'>
                        <h5>Hyperautomation</h5>
                        <br/>
                        <a className='serviceall_di_btn' href='/solutions/hyperautomation'>Learn More</a>
                        
                    </div>
                </div>
                
                

              
                

            </div>
                    {/* <h3>Cybersecurity Managed Services</h3>
                    <ul>
                    <p><li>GRC-as-a-service</li></p>
                    <br/>
                    <p><li>SOC-as-a-service</li></p>
                    <br/>
                    <p><li>Managed Detection and Response (MDR)</li></p>
                    <br/>
                    <p><li>Managed Gateways</li></p>
                    <br/>
                    <p><li>Managed Security Events Monitoring</li></p>
                    <br/>
                    <p><li>Managed Vulnerability Scanning</li></p>
                    <br/>
                    <p><li>Managed Threat Intelligence</li></p>
                    <br/>
                    <p><li>Managed Deception Services</li></p>
                    <br/>
                    <p><li>Threat Hunting as a Service</li></p>
                    <br/>
                    <p><li>Managed Application Security Services</li></p>
                    </ul> */}
                    
                </div>
                <div className='about_col'>
                 
                </div>
            </div>
        </div>
    </section>
    <br/>
    <br/>
    <hr className='about_line'/>



    

    <Reach />
    </>
    
  )
}
