import React,{useState, useEffect} from 'react'
import Reach from '../ReachOut/Reach'
import './ServicesCAS.css';
import cyberconsult from '../../assets/service_about/cas1.png'
import AnglesDown from '../../assets/about/angles_down3.png';
import Consultings from '../../assets/service_about/consulting.png';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faShieldHalved, faChartArea } from '@fortawesome/free-solid-svg-icons';
import DataClass from '../../assets/service_about/dataclass_icon.png';
import BusinessIcon from '../../assets/service_about/busres_icon.png';
import SecWare from '../../assets/service_about/secaware_icon.png';
import Incres from '../../assets/service_about/incres_icon.png';
import AccManage from '../../assets/service_about/accmanage_icon.png';
import OrgBuild from '../../assets/service_about/orgbuild_icon.png';
import CyberTrsain from '../../assets/service_about/cybertrain_icon.png';
import Compliance from '../../assets/service_about/compliance_icon.png';
import CyberStrategy from '../../assets/service_about/cyberstrategy_icon.png';

export default function ServicesCAS() {
    /*Smaple */

    const faChartAreaIcon = <FontAwesomeIcon icon={faChartArea} />

    const listenToScrolls = () => {
        let heightToHidden = 5;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    
        (windowScroll > heightToHidden) ? setIsVisible(false) : setIsVisible(true);
    }
    
    useEffect(()=>{
        window.addEventListener("scroll", listenToScrolls)
    })
    
    const [isVisible, setIsVisible] = useState(true);

    /*Smaple */
  return (
    <>

    <Helmet>
        <title>Consulting and Advisory Services</title>
        {/* <meta name="description" content="Elevate, Innovate, Integrate – Your Complete IT Partner in Dubai"/> */}
        <meta name="description" content="At SIBR, we provide Consulting and Advisory Services especially in GRC Consulting, Data Classification, Business Resiliency Consulting, Security Awareness Consulting, Cybersecurity Strategies, Access Management Consulting, Security Operations Center, Threat Intelligence and Incident Response Consulting Services"/>
        <link rel='canonical' href=''/>
    </Helmet>
    

    <section id="serviceCASdetails">
        
        <div className="serviceCAS_banner">
           
        </div>

        <div className="container">
            <div className='serviceCAS_outer'>
          
                <h1 className='serviceCAS_title'>
                <span>
                    Consulting and Advisory Services</span>
                </h1>
               
              
                
            </div>
            
        </div>

        

        

    </section>

    <hr className='about_line'/>

    {isVisible && (
        <div className="cas_down_arrow">
            
            <img src={AnglesDown} alt='Alt'></img>
   
        </div>
      )}

    {/* <section id='serviceCAS_container'>
        <div className='container'>
            <div className='serviceCAS_wrapper'>
                <div className='serviceCAS_col'>
                    <p className='cas_title_para'>At SiBr, we specialize in providing comprehensive IT Consulting and Advisory Services tailored to meet the unique technology needs and challenges of our clients. With a team of experienced IT professionals and industry experts</p>
                   
                    <ul>
                    <p><li>GRC Consulting Services, focusing on Local (NCA, SAMA) and International Frameworks (ISO, GDPR, NIST)</li></p>
                    <br/>
                    <p><li>Data Classification Consulting Services</li></p>
                    <br/>
                    <p><li>Business Continuity Plan and Business Resiliency</li></p>
                    <br/>
                    <p><li>Security Awareness Consulting Services</li></p>
                    <br/>
                    <p><li>Cybersecurity Strategies and Program Development</li></p>
                    <br/>
                    <p><li>Identity and Access Management Consulting Services</li></p>
                    <br/>
                    <p><li>Cybersecurity Organization Building Consulting Services</li></p>
                    <br/>
                    <p><li>Security Operations Center (SOC) Consulting</li></p>
                    <br/>
                    <p><li>Cybersecurity Training and Education Program</li></p>
                    <br/>
                    <p><li>Threat Intelligence and Incident Response Consulting and Management vCISO</li></p>
                    </ul>
                    <div className='btn_wrapper cas_get_started'>
                        <a className='btn' href='/contact-us/'>Get Started</a>
                    </div>
                </div>
                <div className='about_col'>
                    <div className='serviceCAS_img'>
                        
                        <img src={Consultings} alt="Data Management"/>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

<br/>
    <div className='cas_container'>
    <p className='cas_para_left'>Consulting and Advisory Services in cybersecurity play a pivotal role in helping organizations navigate the intricate landscape of digital threats and safeguard their assets effectively. These services encompass a broad spectrum of expertise, ranging from strategic planning to tactical execution, tailored to the unique needs and challenges of each client. We offer invaluable insights into emerging threats, regulatory requirements, and industry best practices, guiding organizations in identifying vulnerabilities and developing robust security strategies. With a focus on risk assessment, compliance, incident response, and security program governance, these services empower organizations to proactively manage cyber risks and protect their critical data and infrastructure.</p>
    </div>
    <div className='cas_timeline'>
        
        <div className='cas_timeline_container cas_right_container'>
            <img src={Compliance} alt=''/>
            <div className='cas_text_box'>
                <h3>GRC Consulting Services</h3>
                <br/>
                <small>We offer specialized Governance, Risk, and Compliance (GRC) Consulting Services designed to assist organizations in navigating the complex landscape of regulatory requirements and risk management. Our team of experts provides tailored solutions to address the unique needs and challenges of each client, ensuring compliance with industry standards and regulations while optimizing governance practices.</small>
                <span className='cas_left_container_arrow'></span>
            </div>
        </div>
        <div className='cas_timeline_container cas_right_container'>
        <img src={DataClass} alt=''/>
            <div className='cas_text_box'>
                <h3>Data Classification Consulting Services</h3>
                <br/>
                <small>We provide specialized Data Classification Consulting Services to help organizations effectively manage and protect their data assets. Data classification is essential for ensuring that sensitive information is handled appropriately, in accordance with regulatory requirements and organizational policies. Our team of experts offers tailored solutions to assist organizations in classifying their data, implementing data classification frameworks, and establishing policies.</small>
                <span className='cas_right_container_arrow'></span>
            </div>
        </div>
        <div className='cas_timeline_container cas_right_container'>
        <img src={BusinessIcon} alt=''/>
            <div className='cas_text_box'>
                <h3>Business Continuity Plan & Business Resiliency</h3>
                <br/>
                <small>We provide specialized Business Continuity Plan (BCP) and Business Resiliency Consulting Services to help organizations prepare for and recover from disruptions and disasters. Business continuity planning is crucial for ensuring that organizations can continue operating in the face of unforeseen events such as natural disasters, cyber attacks, or equipment failures. Our team of experts offers tailored solutions to assist organizations in developing robust business continuity plans and enhancing their overall resilience.</small>
                <span className='cas_left_container_arrow'></span>
            </div>
        </div>
        <div className='cas_timeline_container cas_right_container'>
        <img src={SecWare} alt=''/>
            <div className='cas_text_box'>
                <h3>Security Awareness Consulting Services</h3>
                <br/>
                <small>We provide specialized Security Awareness Consulting Services to help organizations educate and empower their employees to become proactive defenders against cyber threats. Security awareness is a critical component of any comprehensive cybersecurity strategy, as human error remains one of the leading causes of security incidents. Our team of experts offers tailored solutions to assist organizations in developing and implementing effective security awareness programs.</small>
                <span className='cas_right_container_arrow'></span>
            </div>
        </div>
        <div className='cas_timeline_container cas_right_container'>
        <img src={CyberStrategy} alt=''/>
            <div className='cas_text_box'>
                <h3>Cybersecurity Strategies & Program Development</h3>
                <br/>
                <small>We specialize in Cybersecurity Strategies & Program Development, offering tailored consulting and advisory services to help organizations strengthen their security posture and effectively mitigate cyber threats. Our team of experts collaborates closely with clients to develop comprehensive cybersecurity strategies and programs aligned with their unique business goals, risk tolerance, and regulatory requirements.</small>
                <span className='cas_left_container_arrow'></span>
            </div>
        </div>
        <div className='cas_timeline_container cas_right_container'>
        <img src={AccManage} alt=''/>
            <div className='cas_text_box'>
                <h3>Identity and Access Management Consulting Services</h3>
                <br/>
                <small>We specialize in providing Identity and Access Management (IAM) Consulting Services to help organizations enhance their security posture, streamline access control processes, and ensure compliance with regulatory requirements. Our team of experts collaborates closely with clients to develop tailored IAM strategies and solutions that align with their business objectives and unique security needs.</small>
                <span className='cas_right_container_arrow'></span>
            </div>
        </div>
        <div className='cas_timeline_container cas_right_container'>
        <img src={OrgBuild} alt=''/>
            <div className='cas_text_box'>
                <h3>Cybersecurity Organization Building Consulting Services</h3>
                <br/>
                <small>We offer specialized Cybersecurity Organization Building Consulting Services to help organizations establish and optimize their cybersecurity capabilities. Building a robust cybersecurity organization is essential for effectively managing cyber risks, responding to threats, and protecting valuable assets. Our team of experts collaborates closely with clients to develop tailored strategies and solutions that align with their business goals, risk tolerance, and regulatory requirements.</small>
                <span className='cas_left_container_arrow'></span>
            </div>
        </div>
        <div className='cas_timeline_container cas_right_container'>
        <img src={CyberTrsain} alt=''/>
            <div className='cas_text_box'>
                <h3>Cybersecurity Training and Education Program</h3>
                <br/>
                <small>We offer specialized Cybersecurity Training and Education Programs designed to empower organizations and individuals with the knowledge and skills needed to effectively protect against cyber threats. With the ever-evolving threat landscape, cybersecurity training is essential for building a strong defense and fostering a security-conscious culture within organizations. Our team of experts collaborates closely with clients to develop customized training programs that address their specific needs, challenges, and objectives.</small>
                <span className='cas_right_container_arrow'></span>
            </div>
        </div>
        <div className='cas_timeline_container cas_right_container'>
        <img src={Incres} alt=''/>
            <div className='cas_text_box'>
                <h3>Threat Intelligence and Incident Response Consulting and Management vCISO</h3>
                <br/>
                <small>We offer specialized Threat Intelligence and Incident Response Consulting and Management services, including virtual Chief Information Security Officer (vCISO) support, to help organizations enhance their cybersecurity posture and effectively respond to security incidents. With the increasing sophistication and frequency of cyber threats, having access to timely threat intelligence and robust incident response capabilities is essential for mitigating risks and minimizing the impact of security breaches. Our team of experienced cybersecurity professionals collaborates closely with clients to develop tailored strategies and solutions that align with their business goals, risk tolerance, and regulatory requirements.</small>
                <span className='cas_left_container_arrow'></span>
            </div>
        </div>
        

        
    </div>

    <div className='cas_container'>
    <h2 className='cas_para_headleft'>What is Consulting and Advisory Services?</h2>
    <p className='cas_para_left'>Consulting and Advisory Services in cybersecurity encompass a broad range of specialized expertise and guidance provided by cybersecurity professionals to organizations seeking to enhance their security posture. These services typically involve strategic planning, risk assessment, and implementation of cybersecurity best practices tailored to the organization's specific needs and industry regulations. Cybersecurity consultants offer insights into emerging threats, regulatory compliance requirements, and industry standards, helping organizations identify vulnerabilities and develop effective strategies to mitigate risks. Additionally, advisory services provide ongoing support and guidance, helping organizations navigate complex cybersecurity challenges, respond to incidents, and optimize their security operations. Overall, Consulting and Advisory Services in cybersecurity play a crucial role in helping organizations proactively manage cyber risks and protect their sensitive data and digital assets from evolving threats.</p>
    <h2 className='cas_para_headleft'>Consulting and Advisory Services At SiBr</h2>
    <p className='cas_para_left'>At SiBr, we offer comprehensive Consulting and Advisory Services in cybersecurity aimed at helping organizations strengthen their security posture and navigate the complex landscape of cyber threats. Our team of experienced cybersecurity professionals provides tailored solutions and strategic guidance to address the unique security challenges faced by each client.<br/>By leveraging our Consulting and Advisory Services in cybersecurity, organizations can enhance their security posture, reduce the risk of security breaches, and effectively protect their sensitive data and digital assets from cyber threats. Our team's expertise, combined with a proactive and tailored approach, enables organizations to achieve their cybersecurity objectives efficiently and effectively.</p>
    </div>


    <Reach />
    
    </>
  )
}
