import React from 'react'
import './ContactDetails.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faSquareInstagram,faLinkedinIn, faXTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import Coverage from './Coverage';
import { Helmet } from 'react-helmet-async';




export default function ContactDetails() {
    //Icons
    const faFacebookIcon = <FontAwesomeIcon icon={faFacebookF} />

    const faSquareInstagramIcon = <FontAwesomeIcon icon={faSquareInstagram} />

    const faLinkedinInIcon = <FontAwesomeIcon icon={faLinkedinIn} />

    const faXTwitterIcon = <FontAwesomeIcon icon={faXTwitter} />

    const faWhatsappIcon = <FontAwesomeIcon icon={faWhatsapp} />



 
    
        

  return (
    <>
    <Helmet>
        <title>SIBR Computers</title>
      

        <meta name="description" content="At SIBR, we empower your business's growth with our profound industry knowledge.  Feel free to ask your queries or would need any assistance, leave us a message, and we will get back to you"/>
        <link rel='canonical' href='/contact-us/'/>
    </Helmet>
    <section id='contact_details'>
        <div className='container'>
            <div className='contact_wrapper'>
                <div className='contactdetails_col'>
                    <div className='contactdetails_image'>
                        <h3>We'd Love to hear <br/>from you</h3>
                        <p className='embark'>Embark on your digital innovation journey with us! Connect with our experts. For inquiries or assistance, drop us a message, and we'll respond promptly.</p>
                        <h6>Email:</h6>
                        <p><span>sales@si-br.com</span></p>
                        <br/>
                        <h6>Phone:</h6>
                        <p><span>+971 42 68 8750</span></p>
                        <br/>
                        <h6>Address:</h6>
                        {/* <a href="mailto:shahmadraza@hotmailcom" className='mail'>sales@si-br.com</a> */}
                        <p><span>PO Box 45195</span></p>
                        <hr className='about_line'/>
                        <br/>
                        <p><span>Connect with Us:</span></p>
                        <br/>
                        <a href='https://www.facebook.com/profile.php?id=61555039501778' target='_blank'>{faFacebookIcon}</a>
                        <a href='https://www.linkedin.com/company/sibr-llc' target='_blank'>{faLinkedinInIcon}</a>
                    </div>
                </div>
                <div className='contactdetails_col'>
                    <h2>Get in Touch with us</h2>
                    <br />
       

                    <form action="https://api.web3forms.com/submit" method="POST">
                        <div className='inputdetails_wrapper'>
                        <input type="hidden" name="access_key" value="e5a0b783-f9f4-41a9-a8aa-cbcb28dfb9d9"/>
                            <input type='text' className='form-control' name='name' placeholder='Your name*' autoComplete='off' required/>
                        </div>
                        <div className='inputdetails_wrapper'>
                            <input type='text' className='form-control' name='phone' placeholder='Your phone*' autoComplete='off' required/>
                        </div>
                        <div className='inputdetails_wrapper'>
                            <input type='email' className='form-control' name='email' placeholder='Your email*' autoComplete='off' required/>
                        </div>
                        <div className='inputdetails_wrapper'>
                            <input type='text' className='form-control' name='company' placeholder='Your company*' autoComplete='off' required/>
                        </div>
                        <div className='inputdetails_wrapper'>
                            <textarea placeholder='Write a message*' name='message' required></textarea>
                        </div>
                        <div className='btn_wrapper contact_submit'>
                            <button type='submit' className='btn' formtarget="_blank">
                                Submit
                            </button>
                        </div>
                    </form>
                    
                    
                    {/* <form onSubmit={handleSubmit} action="https://formsubmit.co/shahmadraza@hotmail.com" method="POST" id='contacts'>
                        <div className='inputdetails_wrapper'>
                            <input type='text' className='form-control' placeholder='Your name*' name="name" value={formValues.name} onChange={handleChange}/>
                        </div>
                        <p>{formErrors.name}</p>
                        <div className='inputdetails_wrapper'>
                            <input type='text' className='form-control' placeholder='Your phone*' name="phone" value={formValues.phone} onChange={handleChange}/>
                        </div>
                        <p>{formErrors.phone}</p>
                        <div className='inputdetails_wrapper'>
                            <input type='email' className='form-control' placeholder='Your email*' name="email" value={formValues.email} onChange={handleChange}/>
                        </div>
                        <p>{formErrors.email}</p>
                        <div className='inputdetails_wrapper'>
                            <input type='text' className='form-control' placeholder='Your company*' name="company" value={formValues.company} onChange={handleChange}/>
                        </div>
                        <p>{formErrors.company}</p>
                        <div className='inputdetails_wrapper'>
                            <textarea placeholder='Write a message*' name="message" value={formValues.message} onChange={handleChange}></textarea>
                        </div>
                        <p>{formErrors.message}</p>
                        <div className='btn_wrapper'>
                        <button type='submit' className='btn'>
                                Submit
                            </button>
                        </div>
                    </form> */}
                </div>
            </div>
        </div>
        <Coverage/>
        <br/>
        <br/>
        <br/>
    </section>
    

    </>
  )
}
