import React from 'react'
import './Contact.css'
import ContactImage from '../../assets/contact/contact.jpg'

export default function Contact() {
  return (
    <>

    

    
    
    
    <section id='contact'>
        <div className='container'>
            <div className='contact_wrapper'>
                <div className='contact_col'>
                    <div className='contact_image'>
                        <img src={ContactImage} alt='SiBr Contact'/>
                    </div>
                </div>
                <div className='contact_col'>
                    <h2>Contact SiBr</h2>
                    <form action="https://formsubmit.co/shahmadraza@hotmail.com" method="POST">
                        <div className='input_wrapper'>
                            <input type='text' className='form-control' placeholder='Your name..' autoComplete='off'/>
                        </div>
                        <div className='input_wrapper'>
                            <input type='text' className='form-control' placeholder='Your phone..' autoComplete='off'/>
                        </div>
                        <div className='input_wrapper'>
                            <input type='email' className='form-control' placeholder='Your email..' autoComplete='off'/>
                        </div>
                        <div className='input_wrapper'>
                            <textarea placeholder='Write a message..'></textarea>
                        </div>
                        <div className='btn_wrapper'>
                            <button type='submit' className='btn'>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
