import React from 'react'
import './Home.css';
// import BannerImage from '../../assets/banner/banner-img.jpg'
import BannerImage from '../../assets/banner/main4.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faShieldHalved, faChartArea } from '@fortawesome/free-solid-svg-icons';
import About from '../About/About';
import Services from '../Services/Services';
import Blog from '../Blog/Blog';
import Contact from '../Contact/Contact';
import ContactDetails from '../ContactDetails/ContactDetails';
import PartnerSlider from '../Partners/PartnerSlider';
import { Helmet } from 'react-helmet-async';

// import Bann from '../../assets/banner/banner05.jpg';



export default function Home() {
    //Icons
    const faChartIcon = <FontAwesomeIcon icon={faChartLine} />
    const faShieldIcon = <FontAwesomeIcon icon={faShieldHalved} />
    const faChartAreaIcon = <FontAwesomeIcon icon={faChartArea} />
  return (
    

    <>
    {/* <Helmet>
        <title>SIBR Computers</title>
        
       
        <meta name="description" content="The SIBR is a trusted IT company in Dubai, where technology meets innovation. As a leading IT solutions provider, we specialize in delivering cutting-edge services that propel businesses into the digital future."/>
        <link rel='canonical' href='/'/>
    </Helmet> */}
    <Helmet>
        <title>SIBR Computers</title>
      
        <meta name="description" content="The SIBR is a trusted IT company in Dubai, where technology meets innovation. As a leading IT solutions provider, we specialize in delivering cutting-edge services that propel businesses into the digital future."/>
        <link rel='canonical' href='/'/>
    </Helmet>

    <section id="home">
        <div className="banner_image">
        {/* <video className='videoTag' autoPlay loop muted>
    <source src={VideoWall} type='video/mp4' />
</video> */}
            

        </div>
        <div className="container">
            <div className='banner_outer'>
                <div className='col'>
                <h1 className='title'>
                    {/* WE PROMOTE YOUR <span>BUSINESS</span> */}
                    ELEVATE YOUR BUSINESS WITH OUR<span><br/> EXPERTISE</span>
                </h1>
                <p>
                Elevate your business growth with our industry expertise. <br/>Collaborate with us for resource flexibility, efficient operations,<br/> and robust cybersecurity."


                </p>
                <div className='btn_wrapper breached_btn'>
                    <a className='btn' href='/contact-us/'>Get Started</a>
                </div>
                </div>
                <div className='col'>
                    {/* <div className='sub_banner_image'>
                        <img src={BannerImage} alt='SiBr Banner' />
                    </div>
                    <div className='banner_stlye_1'>
                        {faChartIcon}
                        <h4>Empower</h4>
                    </div>

                    <div className='banner_stlye_1 banner_style_2'>
                        {faShieldIcon}
                        <h4>Connect</h4>
                    </div>

                    <div className='banner_stlye_1 banner_style_3'>
                        {faChartAreaIcon}
                        <h4>Innovate</h4>
                    </div> */}
                </div>
            </div>
            
        </div>


    </section>
    <About />
   
    
    <PartnerSlider />
    <ContactDetails />
    {/* <Contact /> */}
    </>
  )
}
