import React , { useState, useRef, Component } from 'react'
import './PartnerSlider.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
// import Tenable from '../../assets/partners/tenable.jpeg'
// import Tenable1 from '../../assets/partners/tenable1.jpg'
// import Opentext from '../../assets/partners/opentext.jpeg'
// import Knowbe4 from '../../assets/partners/knowbe4.jpeg'
// import Darktrace from '../../assets/partners/darktrace.jpeg'

import Apple from '../../assets/partners/apple3.jpg'
import Cisco from '../../assets/partners/cisco6.jpg'
import Dell from '../../assets/partners/dell.jpg'
import GoogleWS from '../../assets/partners/googlews.jpg'
import HP from '../../assets/partners/hp.jpg'
import Lenovo from '../../assets/partners/lenovo.jpg'
import Logitech from '../../assets/partners/logitech.jpg'
import MS from '../../assets/partners/ms.jpg'
import Neat from '../../assets/partners/neat.jpg'
import Poly from '../../assets/partners/poly.jpg'
import Samsung from '../../assets/partners/samsung.jpg'
import Targus from '../../assets/partners/targus.jpg'
import Zoom from '../../assets/partners/zoom.jpg'
import Motadata from '../../assets/partners/motadata.jpg'
import Blanco from '../../assets/partners/blanco.jpg'
import Rubrik from '../../assets/partners/rubrik.jpg'
import Fortra from '../../assets/partners/fortra.jpg'
import Darkrace from '../../assets/partners/darkrace.jpg'
import Fortinet from '../../assets/partners/fortinet.jpg'
import Opentext from '../../assets/partners/opentext.jpg'
import Tenable from '../../assets/partners/tenable.jpg'
import Knowbe4 from '../../assets/partners/knowbe4.jpg'
import { Helmet } from 'react-helmet-async';




function Arrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "black" }}
        onClick={onClick}
      />
    );
  }


export default function PartnerSlider() {


    var settings = {
        dots: false,
        
        infinite: false,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,

        nextArrow: <Arrow />,
        prevArrow: <Arrow />,



        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    







  return (
    <>
    <Helmet>
        <title>SIBR Partners</title>
      
      
        <meta name="description" content="At SIBR, we partnered with Apple, Cisco, Dell, Google Workspace, HP, Lenovo, Logitech, Microsoft 365, Neat, Poly, Samsung, Targus, Zoom, Motadata, Blanco, Fortra, Darkrace, Fortinet, Opentext, Tenable and Knowbe4"/>
        <link rel='canonical' href=''/>
    </Helmet>
    <div className='container slide_back'>
        <h2>Our Partners</h2>
        <Slider {...settings}>
          <div className='partner_slider_col'>
            <img src={Apple} alt='Apple' className='slide_img'/>
          </div >
          <div className='partner_slider_col'>
          <img src={Cisco} alt='Cisco' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Dell} alt='Dell' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={GoogleWS} alt='GoogleWorkspace' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={HP} alt='HP' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Lenovo} alt='Lenovo' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Logitech} alt='Logitech' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={MS} alt='Microsoft' className='slide_img'/>
          </div>
         

          <div className='partner_slider_col'>
          <img src={Neat} alt='Neat' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Poly} alt='Poly' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Samsung} alt='Samsung' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Targus} alt='Targus' className='slide_img'/>
          </div>

          <div className='partner_slider_col'>
          <img src={Zoom} alt='Zoom' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Motadata} alt='Motadata' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Blanco} alt='Blanco' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Fortra} alt='Fortra' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Darkrace} alt='Darkrace' className='slide_img'/>
          </div>

          <div className='partner_slider_col'>
          <img src={Fortinet} alt='Fortinet' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Opentext} alt='Opentext' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Tenable} alt='Tenable' className='slide_img'/>
          </div>
          <div className='partner_slider_col'>
          <img src={Knowbe4} alt='Knowbe4' className='slide_img'/>
          </div>
        </Slider>
      </div>
    </>
    
    
    
    

  )
}
