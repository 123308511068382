import React, {useState, useEffect} from 'react'
import './ServiceSCPS.css';
import Reach from '../ReachOut/Reach'
import SecInfra from '../../assets/service_about/secinfra.png'
import SecurityInfra from '../../assets/service_about/sec_infra.png'
import AnglesDown from '../../assets/about/angles_down3.png';
import { Helmet } from 'react-helmet-async';import incident from '../../assets/service_about/incident_icon.png';
import cctvSystem from '../../assets/service_about/cctvsystem_icon.png';
import CamInstall from '../../assets/service_about/caminstall_icon.png';
import NetInt from '../../assets/service_about/netint_icon.png';
import DevDeploy from '../../assets/service_about/devdeploy_icon.png';
import AccTech from '../../assets/service_about/acctech_icon.png';
import AccControl from '../../assets/service_about/acccontrol_icon.png';
import PeopleCount from '../../assets/service_about/peoplecount_icon.png';
import Survelliance from '../../assets/service_about/survelliance_icon.png';
import Assessment from '../../assets/service_about/assessment_icon.png';
import SecMeasure from '../../assets/service_about/secmeasure_icon.png';


export default function ServiceSCPS() {
    /*Smaple */

    const listenToScrolls = () => {
        let heightToHidden = 5;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    
        (windowScroll > heightToHidden) ? setIsVisible(false) : setIsVisible(true);
    }
    
    useEffect(()=>{
        window.addEventListener("scroll", listenToScrolls)
    })
    
    const [isVisible, setIsVisible] = useState(true);

    /*Smaple */
  return (
    <>


    <Helmet>
        <title>Security Infrastructure</title>
      
        <meta name="description" content="At SIBR, we provide services in Security Infrastructure especially in design & implement tailored CCTV system, install cameras in strategic locations, integrate CCTV systems with existing networks, install access devices such as card readers, biometric scanners, , configure access levels and permissions, implement people counting technologies and utilize video analytics algorithms for accurate counting"/>
        <link rel='canonical' href=''/>
    </Helmet>
    
    <section id="serviceSCPSTdetails">
        
        <div className="serviceSCPST_banner">
           
        </div>

        <div className="container">
            <div className='serviceSCPST_outer'>
          
                <h1 className='serviceSCPST_title'>
                <span>
                Security Infrastructure</span>

                </h1>
               
              
                
            </div>
            
        </div>

        

        

    </section>

    <hr className='about_line'/>

    {isVisible && (
        <div className="csps_down_arrow">
            
            <img src={AnglesDown} alt='Alt'></img>
   
        </div>
      )}

    {/* <section id='serviceSCPS_container'>
        <div className='container'>
            <div className='serviceSCPS_wrapper'>
                <div className='serviceSCPS_col'>
                    <p className='si_title_para'>At SiBr, we specialize in providing robust and reliable IT Security Infrastructure Services to help organizations protect their digital assets and ensure the confidentiality, integrity, and availability of their IT systems.</p>
                  
                    <ul>
                    <p><li>We conduct a thorough assessment of the client's premises, identifying key areas for surveillance, and understanding specific security needs.</li></p>
                    <br/>
                    <p><li>We design a tailored CCTV system based on the assessment, considering factors such as camera types, locations, and integration with existing security infrastructure.</li></p>
                    <br/>
                    <p><li>Professionally install cameras in strategic locations, ensuring optimal coverage and adherence to security and privacy standards.</li></p>
                    <br/>
                    <p><li> Integrate CCTV systems with existing networks or set up dedicated networks for enhanced performance and security.</li></p>
                    <br/>
                    <p><li>Provide and install access devices such as card readers, biometric scanners, keypads, and mobile access solutions.</li></p>
                    <br/>
                    <p><li>Integrate multiple access technologies for a flexible and layered security approach.</li></p>
                    <br/>
                    <p><li>Define and configure access levels and permissions for different user roles.</li></p>
                    <br/>
                    <p><li>Implement time-based access restrictions for enhanced security during non-operational hours.</li></p>
                    <br/>
                    <p><li>Implement people counting technologies to track the number of individuals entering,exiting, or present in specific areas.</li></p>
                    <br/>
                    <p><li>Utilize video analytics algorithms for accurate counting, even in high-density scenarios.</li></p>
                    </ul>
                    <div className='btn_wrapper si_get_started'>
                        <a className='btn' href='/contact-us/'>Get Started</a>
                    </div>
                </div>
                <div className='about_col'>
                    <div className='serviceSCPS_img'>
                        <img src={SecurityInfra} alt="Data Management"/>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

<br/>
    <div className='scps_container'>
    <p className='scps_para_left'>In today's digital landscape, ICT is vital for business operations, but it also brings increased cyber threats. SiBr provides comprehensive security services to strengthen your digital infrastructure. Our holistic approach addresses your organization's unique needs, ensuring robust protection for your digital assets and systems. Partner with us for peace of mind in navigating today's complex threat landscape.</p>
    </div>
    <div className='scps_timeline'>
        
        <div className='scps_timeline_container scps_right_container'>
            <img src={Assessment} alt=''/>
            <div className='scps_text_box'>
                <h3>Comprehensive Premises Assessment</h3>
                <br/>
                <small>We conduct a thorough assessment of the client's premises, identifying key areas for surveillance, and understanding specific security needs.</small>
                <span className='scps_left_container_arrow'></span>
            </div>
        </div>
        <div className='scps_timeline_container scps_right_container'>
        <img src={cctvSystem} alt=''/>
            <div className='scps_text_box'>
                <h3>Customized CCTV System Design</h3>
                <br/>
                <small>We design a tailored CCTV system based on the assessment, considering factors such as camera types, locations, and integration with existing security infrastructure.</small>
                <span className='scps_right_container_arrow'></span>
            </div>
        </div>
        <div className='scps_timeline_container scps_right_container'>
        <img src={CamInstall} alt=''/>
            <div className='scps_text_box'>
                <h3>Strategic Camera Installation</h3>
                <br/>
                <small>Professionally install cameras in strategic locations, ensuring optimal coverage and adherence to security and privacy standards.</small>
                <span className='scps_left_container_arrow'></span>
            </div>
        </div>
        <div className='scps_timeline_container scps_right_container'>
        <img src={NetInt} alt=''/>
            <div className='scps_text_box'>
                <h3>Network Integration for CCTV Systems</h3>
                <br/>
                <small>Integrate CCTV systems with existing networks or set up dedicated networks for enhanced performance and security.</small>
                <span className='scps_right_container_arrow'></span>
            </div>
        </div>
        <div className='scps_timeline_container scps_right_container'>
        <img src={DevDeploy} alt=''/>
            <div className='scps_text_box'>
                <h3>Access Device Deployment</h3>
                <br/>
                <small>Provide and install access devices such as card readers, biometric scanners, keypads, and mobile access solutions.</small>
                <span className='scps_left_container_arrow'></span>
            </div>
        </div>
        <div className='scps_timeline_container scps_right_container'>
        <img src={AccTech} alt=''/>
            <div className='scps_text_box'>
                <h3>Integrated Access Technologies</h3>
                <br/>
                <small>Integrate multiple access technologies for a flexible and layered security approach.</small>
                <span className='scps_right_container_arrow'></span>
            </div>
        </div>
        <div className='scps_timeline_container scps_right_container'>
        <img src={AccControl} alt=''/>
            <div className='scps_text_box'>
                <h3>Access Control Management</h3>
                <br/>
                <small>Define and configure access levels and permissions for different user roles.</small>
                <span className='scps_left_container_arrow'></span>
            </div>
        </div>
        <div className='scps_timeline_container scps_right_container'>
        <img src={SecMeasure} alt=''/>
            <div className='scps_text_box'>
                <h3>Enhanced Security Measures</h3>
                <br/>
                <small>Implement time-based access restrictions for enhanced security during non-operational hours.</small>
                <span className='scps_right_container_arrow'></span>
            </div>
        </div>
        <div className='scps_timeline_container scps_right_container'>
        <img src={PeopleCount} alt=''/>
            <div className='scps_text_box'>
                <h3>Deploying People Counting Technologies</h3>
                <br/>
                <small>Implement people counting technologies to track the number of individuals entering,exiting, or present in specific areas.</small>
                <span className='scps_left_container_arrow'></span>
            </div>
        </div>
        <div className='scps_timeline_container scps_right_container'>
        <img src={Survelliance} alt=''/>
            <div className='scps_text_box'>
                <h3>Enhanced Surveillance</h3>
                <br/>
                <small>Utilize video analytics algorithms for accurate counting, even in high-density scenarios.</small>
                <span className='scps_right_container_arrow'></span>
            </div>
        </div>
        

        
    </div>

    <div className='scps_container'>
    <h2 className='scps_para_headleft'>What is Security Infrastructure?</h2>
    <p className='scps_para_left'>
Security infrastructure refers to the framework of hardware, software, processes, and policies that organizations put in place to protect their digital assets, networks, and physical premises from unauthorized access, cyber threats, and other security risks. This infrastructure is designed to create a secure environment that safeguards sensitive information, maintains the integrity of systems and data, and ensures business continuity. Security infrastructure may include components such as firewalls, antivirus software, intrusion detection systems, access control mechanisms, encryption technologies, surveillance cameras, and security protocols. It plays a crucial role in mitigating security threats and maintaining the overall security posture of an organization.</p>
    <h2 className='scps_para_headleft'>Security Infrastructure At SiBr</h2>
    <p className='scps_para_left'>In today's interconnected world, Information and Communication Technology (ICT) forms the backbone of virtually every aspect of modern business operations. However, with increased reliance on digital technologies comes the pressing need for robust security infrastructure to protect against evolving cyber threats. At SiBr, we recognize the critical importance of establishing a resilient security framework within ICT environments, and we offer comprehensive services to fortify your digital infrastructure.

Our approach to security infrastructure in ICT encompasses a holistic strategy that addresses the unique needs and challenges of your organization.<br/>By partnering with us, you can rest assured that your ICT environment is equipped with a robust security infrastructure that protects your digital assets, preserves the integrity of your systems and data, and enables you to navigate the complexities of today's threat landscape with confidence.</p>
    </div>


    <Reach />
    
    </>
  )
}
