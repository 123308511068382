import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet-async';
import './Privacy.css';

export default function Privacy() {
    const listenToScrolls = () => {
        let heightToHidden = 5;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    
        (windowScroll > heightToHidden) ? setIsVisible(false) : setIsVisible(true);
    }
    
    useEffect(()=>{
        window.addEventListener("scroll", listenToScrolls)
    })
    
    const [isVisible, setIsVisible] = useState(true);
  return (
    <>
    <Helmet>
        <title>Intelligent Networking</title>
      
        <meta name="description" content="At SIBR, we provide services in Intelligent Networking especially in assess the level of security, scalability, and performance, develop a customized network architecture, implement robust security protocols including firewalls, consider VPNs, manage network traffic using QoS settings, optimize Wi-Fi networks, deploy switches and routers, seamless connectivity to cloud services and implement multi-cloud strategies"/>
        <link rel='canonical' href=''/>
    </Helmet>

    <div className='privacy_div'>
        <br/>
        <br/>
        <br/>
        <br/>
    <h1  className='black_class'>Privacy Policy</h1>
        <p className='black_class'><strong>Effective Date:</strong> 1st Feb 2024</p>
        
        <p className='black_class'>Welcome to SiBr. Your privacy is very important to us. This Privacy Policy outlines the types of personal information that is received and collected by www.si-br.com and how it is used.</p>
        <br/>
        <h5 className='black_class'><strong>1. Information We Collect</strong></h5>
        <p className='black_class'>We collect minimal information. However, the types of information we may collect include:</p>
        
        <ul>
            <li className='black_class'><strong>Personal Information:</strong> If you voluntarily provide information through contact forms or email, such as your name, email address, and any other personal information you choose to provide.</li>
            <li className='black_class'><strong>Log Data:</strong> When you visit our Site, our servers may automatically log standard data provided by your web browser. This data may include your IP address, browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details.</li>
        </ul>
        <br/>
        <h5 className='black_class'><strong>2. Use of Information</strong></h5>
        <p className='black_class'>We may use the collected information for the following purposes:</p>
        <ul>
            <li className='black_class'><strong>Communication:</strong> To respond to your inquiries, comments, or requests.</li>
            <li className='black_class'><strong>Improvements:</strong> To analyze how our Site is used and to enhance its functionality and user experience.</li>
            <li className='black_class'><strong>Compliance:</strong> To comply with legal obligations and protect our rights.</li>
        </ul>
        <br/>
        <h5 className='black_class'><strong>3. Cookies and Tracking Technologies</strong></h5>
        <p className='black_class'>Our Site may use cookies and similar tracking technologies to enhance your experience. Cookies are small data files that are placed on your device when you visit a website. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>
        <br/>
        <h5 className='black_class'><strong>4. Sharing of Information</strong></h5>
        <p className='black_class'>We do not sell, trade, or otherwise transfer your personal information to outside parties except as described in this Privacy Policy. We may share your information in the following circumstances:</p>
        <ul>
            <li className='black_class'><strong>Service Providers:</strong> We may engage third-party companies and individuals to perform services on our behalf, such as website hosting and maintenance. These third parties may have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</li>
            <li className='black_class'><strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to valid requests by public authorities.</li>
        </ul>
        <br/>
        <h5 className='black_class'><strong>5. Security of Information</strong></h5>
        <p className='black_class'>We take reasonable measures to protect your information from unauthorized access, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee its absolute security.</p>
        <br/>
        <h5 className='black_class'><strong>6. Third-Party Links</strong></h5>
        <p className='black_class'>Our Site may contain links to external websites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and we cannot accept responsibility or liability for their respective privacy policies.</p>
        <br/>
        <h5 className='black_class'><strong>7. Changes to This Privacy Policy</strong></h5>
        <p className='black_class'>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically for any changes.</p>
        <br/>
        <h5 className='black_class'><strong>8. Contact Us</strong></h5>
        <p className='black_class'>If you have any questions about this Privacy Policy or our treatment of your personal information, please contact us at:</p>
        <p className='black_class'>
            SiBr Computers<br/>
            sales@si-br.com<br/>
            +971 56 125 0429<br/>
        </p>
        <p className='black_class'>Thank you for visiting www.si-br.com. Your privacy is important to us.</p>
        <br/>
        <br/>
        
     

    </div>
    </>
  )
}
