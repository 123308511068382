import React, {useState, useEffect} from 'react'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faFacebookF, faSquareInstagram,faLinkedinIn, faXTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
export default function Footer() {
    //Icons
    const faFacebookIcon = <FontAwesomeIcon icon={faFacebookF} />

    const faSquareInstagramIcon = <FontAwesomeIcon icon={faSquareInstagram} />

    const faLinkedinInIcon = <FontAwesomeIcon icon={faLinkedinIn} />

    const faXTwitterIcon = <FontAwesomeIcon icon={faXTwitter} />

    const faWhatsappIcon = <FontAwesomeIcon icon={faWhatsapp} />
    //Icons
    const faArrowUpIcon = <FontAwesomeIcon icon={faArrowUp} />
    const scrollToTop = () => {
        window.scrollTo({
            top:0,
            behavior:"smooth"
        })
    }

    const listenToScroll = () => {
        let heightToHidden = 250;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;

        (windowScroll > heightToHidden) ? setIsVisible(true) : setIsVisible(false);
    }

    useEffect(()=>{
        window.addEventListener("scroll", listenToScroll)
    })

    // States
    const [isVisible, setIsVisible] = useState(false);
  return (
    <>
    {/* <div className='footer'>
        
           
            <div className='sb_footer section_padding'>
                <div className='sb_footer-links'>
                <div className='sb_footer-links-div'>
                    <h4>For Business</h4>
                    <a href='/employer'>
                        <p>Employer</p>
                    </a>
                    <a href='/healthplan'>
                        <p>healthplan</p>
                    </a>
                    <a href='/individual'>
                        <p>individual</p>
                    </a>
                </div>
                <div className='sb_footer-links-div'>
                    <h4>Resources</h4>
                    <a href='/resource'>
                        <p>individual</p>
                    </a>
                    <a href='/resource'>
                        <p>individual</p>
                    </a>
                    <a href='/resource'>
                        <p>individual</p>
                    </a>

                </div>
                <div className='sb_footer-links-div'>
                    <h4>Partners</h4>
                    <a href='/employer'>
                        <p>Swing tech</p>
                    </a>
                </div>
                <div className='sb_footer-links-div'>
                <h4>Partners</h4>
                    <a href='/employer'>
                        <p>Swing tech</p>
                    </a>
                    <a href='/employer'>
                        <p>Swing tech</p>
                    </a>
                    <a href='/employer'>
                        <p>Swing tech</p>
                    </a>
                    <a href='/employer'>
                        <p>Swing tech</p>
                    </a>
                </div>
                <div className='sb_footer-links-div'>
                    <h4>Coming Soon</h4>
                    <div className='socialmedia'>
                        <p><img src='' alt=''/></p>
                        <p><img src='' alt=''/></p>
                        <p><img src='' alt=''/></p>
                        <p><img src='' alt=''/></p>
                    </div>
                </div>

            </div>
            </div>
            
            <hr></hr>

            <div className='sb_footer-below'>
                <div className='sb_footer-copyright'>
                    <p>
                        @{new Date().getFullYear()} SIBR. All rights reserved.
                    </p>
                    
                </div>
                <div className='sb_footer-below-links'>
                    <a href='/terms'><div><p>Terms and Conditions</p></div></a>
                </div>
            </div>

    </div> */}


    
    
    <section className='container'>
        <footer className='footer'>
            <p>© 2024 Si Br Ltd. All Rights Reserved.</p>
            

        </footer>

    </section>

    {/* <div className='footer-container'>
    
        <div className='footer-links'>
        <div className='footer-link-wrapper'>
            <div className='footer-links-items'>
                <h4>Cyber Security</h4>
                <Link to='/'>Consulting & Advisory Services</Link>
                <Link to='/'>Cybersecurity Services</Link>
                <Link to='/'>Cybersecurity Managed Services</Link>
                <Link to='/'>Influencer</Link>
            </div>
            <div className='footer-links-items'>
                <h4>ICT</h4>
                <Link to='/'>Security Infrastructure</Link>
                <Link to='/'>System Automation</Link>
                <Link to='/'>Intelligent Networking</Link>
                <Link to='/'>Interactive Media Solutions</Link>
                <Link to='/'>Infrastructure Cabling</Link>
                <Link to='/'>Data Facility Planning</Link>
                <Link to='/'>ICT Managed Services</Link>
            </div>
            <div className='footer-links-items'>
                <h4>Digital Innovation</h4>
                <Link to='/'>App Modernization</Link>
                <Link to='/'>Data Management Platforms</Link>
                <Link to='/'>Hyperautomation</Link>
            </div>
        </div>


        </div>

        <section className='social-media'>
            <div className='social-media-wrap'>
                <div className='footer-logo'>
                    <Link to='/' className='social-logo'>
                        Bright Side &nbsp;
                        <i className='fas fa-route'></i>
                    </Link>
                </div> 
                <small className='website-rights'>SIBR 2024</small>
                <div className='social-icons'>
                    <Link className='social-icon-link facebook' to='/' target='_blank' aria-label='Facebool'>
                        <i className='fab fa-facebook-f'/>
                    </Link>
                    <Link className='social-icon-link facebook' to='/' target='_blank' aria-label='Facebool'>
                        <i className='fab fa-facebook-f'/>
                    </Link>
                    <Link className='social-icon-link facebook' to='/' target='_blank' aria-label='Facebool'>
                        <i className='fab fa-facebook-f'/>
                    </Link>
                </div>
            </div> 
        </section>

    </div> */}

    
    
   

    {/* Scroll to Up*/}
    {
        isVisible && (
            <div className='scroll_top' onClick={scrollToTop}>
                {faArrowUpIcon}
                
            </div>
            

        )

        
        
        
        
    }
    
    
    </>
  )
}
