import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { blogList } from './data';
import {Link} from 'react-router-dom';
import Chip from '../OurBlog/BlogChip';
import EmptyList from './EmptyList';
import './OurBlog.css';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faSquareInstagram,faLinkedinIn, faXTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet-async';

export default function OurBlog() {

  const faBlogFacebookIcon = <FontAwesomeIcon icon={faFacebookF} />
  const faBlogXTwitter = <FontAwesomeIcon icon={faXTwitter} />
  const faBlogLinkedinIn = <FontAwesomeIcon icon={faLinkedinIn} />

  const currentPageurl = window.location.href;


  

  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    let blog = blogList.find((blog) => blog.id === (id));
    if (blog) {
      setBlog(blog);
    }
  }, []);
    return (
        <>
      <Helmet>
        <title>SiBr Cybersecurity, Digital Innovation & ICT Blogs</title>
        {/* <meta name="description" content="Elevate, Innovate, Integrate – Your Complete IT Partner in Dubai"/> */}
        <meta name="description" content="Read SiBr blogs to stay updated with the latest trends, insights and innovations in the world of technology."/>
        <link rel='canonical' href=''/>
    </Helmet>

     <div className='blog-contain'>
     <Link className='blog-goBack' to='/'>
        <span> &#8592;</span> <span>Go Back</span>
      </Link>
          {blog ? (
            <div className='blog-wrap'>
              <header>
                {/* <p className='blog-date'>Published {blog.createdAt}</p> */}
                

                
              </header>
              <header>
                {/* <p className='blog-date'>Published {blog.createdAt}</p> */}
                <h1>{blog.title}</h1>
                
              </header>
              <img src={blog.cover} alt='cover' />
              <br/>
              <br/>
              
              
              
              {/* <div className='blog-subCategory'>
                  {blog.subCategory.map((category, i) => (
                    <div key={i}>
                      <Chip label={category} />
                    </div>
                  ))}
                </div> */}
              
              <p className='blog_description'>{blog.description}</p>


              <br/>
              <br/>
              <br/>
              <FacebookShareButton url={currentPageurl} className='fb_icon'>
              {/* <a href='' target='_blank'>{faBlogFacebookIcon}</a> */}
              <FacebookIcon round={true} size={36} ></FacebookIcon>
          </FacebookShareButton>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <LinkedinShareButton url={currentPageurl} className='fb_icon'>
              {/* <a href='' target='_blank'>{faBlogFacebookIcon}</a> */}
              <LinkedinIcon round={true} size={36}></LinkedinIcon>
          </LinkedinShareButton>
          &nbsp; &nbsp; &nbsp; &nbsp;

          <TwitterShareButton url={currentPageurl} className='fb_icon'>
              {/* <a href='' target='_blank'>{faBlogFacebookIcon}</a> */}
              <TwitterIcon round={true} size={36}></TwitterIcon>
          </TwitterShareButton>
             
            </div>
            
          ) : (
            <EmptyList />
          )}

</div>

          


        </>
      );
    };