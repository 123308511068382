import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceFrownOpen } from '@fortawesome/free-solid-svg-icons';
import './PageNotFound.css';
import FaceIcon from '../../assets/contact/face_icon_white.png'

export default function PageNotFound() {
    const faFaceFrownOpenIcon = <FontAwesomeIcon icon={faFaceFrownOpen} />
  return (
    <>
    <section id="pagenotfound">

        <div className="container">
            <div className='pagenotfound_Icon'>

                <img src={FaceIcon} alt='Not Found'></img>
                <h1>404</h1>
                <p>Uh-oh! It seems like you've taken a detour into the digital unknown. The page you were looking for doesn't match any page in our cyber map.</p>
              
                <p>But don't worry; let's get you back on track:</p>
             
                <div className='btn_wrapper'>
                    <a className='btn' href='/home'>Back To Home</a>
                </div>
               
            </div>

           
            
        </div>

    </section>
    </>
  )
}
