import React from 'react'
import './EmptyList.css'
import EmptyListIcon from '../../assets/service_about/13525-empty.gif';


export default function EmptyList() {
  return (
    <div className='emptyList_wrapper'>
        <img src={EmptyListIcon} alt='empty'/>
      
    </div>
  )
}
