import React, {useState, useEffect} from 'react'
import './AboutDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faChartBar, faCopy, faMarker, faUserGear, faCoins, faLightbulb, faBullseye, faHandshake, faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { Link, Outlet } from 'react-router-dom';
import Route from '../Router/Route';
import ServiceDetails from '../ServiceDetails/ServiceDetails';
import { BrowserRouter, Navigate, Router, Routes, useNavigate } from 'react-router-dom';
import Reach from '../ReachOut/Reach';
import AnglesDown from '../../assets/about/angles_down3.png';





export default function AboutDetails() {
    const faLaptopCodeIcon = <FontAwesomeIcon icon={faLaptopCode} />
    const faChartBarIcon = <FontAwesomeIcon icon={faChartBar} />
    const faCopyIcon = <FontAwesomeIcon icon={faCopy} />
    const faMarkerIcon = <FontAwesomeIcon icon={faMarker} />
    const faUserGearIcon = <FontAwesomeIcon icon={faUserGear} />
    const faCoinsIcon = <FontAwesomeIcon icon={faCoins} />
    const faLightbulbIcon = <FontAwesomeIcon icon={faLightbulb} />
    const faBullseyeIcon = <FontAwesomeIcon icon={faBullseye} />
    const faHandshakeIcon = <FontAwesomeIcon icon={faHandshake} />
    const faAnglesDownIcon = <FontAwesomeIcon icon={faAnglesDown} />


    /*Smaple */

    const listenToScrolls = () => {
        let heightToHidden = 5;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    
        (windowScroll > heightToHidden) ? setIsVisible(false) : setIsVisible(true);
    }
    
    useEffect(()=>{
        window.addEventListener("scroll", listenToScrolls)
    })
    
    const [isVisible, setIsVisible] = useState(true);

    /*Smaple */
  return (
    <>
    <section id="aboutDetails">
        
        <div className="aboutDetails_banner">
           
        </div>

        <div className="container">
            <div className='aboutDetails_outer'>
          
                <h6 className='aboutDetails_title'>
                <span>WELCOME TO<br />THE SIBR</span>
                </h6>
               
            </div>

           
            
        </div>

        

        

        

        

        

        

    </section>



    <hr className='about_line'/>

    {isVisible && (
        <div className="down_arroww">
            
            <img src={AnglesDown} alt='Alt'></img>
   
        </div>
      )}
      


    {/* <section id='aboutDetails_maincontainer'>
        <div className='container'>
            <div className='aboutDetails_mainwrapper'>
                <div className='aboutDetails_maincol'>
                 
                    <p>The name synonymous with unparalleled IT services and consultancy. With a prestigious presence in the UAE, KSA, Pakistan, UK, US, and Canada, SiBr has established itself as a cornerstone in the IT sector. Our expertise spans across diverse industries, catering to clients in real estate, education, banking, finance, fintech, hospitality, transportation, telecommunications, and government sectors.</p>
 
                </div>
            </div>
        </div>

        
    </section> */}



    <section>
        <div className='container'>
        <br/>
        <p>The name synonymous with unparalleled IT services and consultancy. With a prestigious presence in the UAE, KSA, Pakistan, UK, US, and Canada, SiBr has established itself as a cornerstone in the IT sector. Our expertise spans across diverse industries, catering to clients in real estate, education, banking, finance, fintech, hospitality, transportation, telecommunications, and government sectors.</p>
        <br/>
    <div className='aboutdel_wrapper'>
                <div className='aboutdel_box'>
                    <div className='service_icon blue_icon'>{faLightbulbIcon}</div>
                    <h4 className='aboutLines'>Expertise</h4>
                    
                    
                    <div id="hero" className='service_content'>
                        <h5>Our Team's Excellence &<br/>Innovation</h5>
                        <p>At SiBr, we pride ourselves on our rich history in the IT field, led by our CEO with an astounding 43 years of experience since 1981. Our team is a blend of seasoned professionals and innovative minds, holding prestigious certifications such as CISSP, PMP, CCISO, CHFI, CISA, CISM, CEH, and many more. We bring together over a century of combined experience, ensuring that our clients receive solutions that are not only cutting-edge but also steeped in industry wisdom.</p>
                        <br/>
                        {/* <a href="ServiceDetails" exact target="_blank" className='read'>Read More</a> */}
                        

                        {/* <Route exact path='./ServiceDetails'
                            element={<ServiceDetails />}>
                        </Route> */}

                        
                        

                            {/* <Link to="./ServiceDetails">Read More</Link> */}
                            {/* <Link to="/ServiceDetails">Read More</Link> */}
                            

                        
                        
                        
                        {/* <BrowserRouter>
                            <div>
                                <Route exact path="/ServiceDetails" component={ServiceDetails} />
                            </div>
                        </BrowserRouter> */}
                        {/* <a href='./ServiceDetails' className='read' onClick={navigateToServiceDetails}>Read More</a> */}
                        {/* <a href={navigateToServiceDetails} className='read'>Read More</a> */}
                        {/* <Link to="../ServiceDetails" className='read'>Read More</Link> */}
                        {/* <a href='./ServiceDetails' className='read'>Read More</a> */}
                        
                    </div>
                    {/* <BrowserRouter>
                            <Routes>
                            
                            <Route path="/ServiceDetails" element={<ServiceDetails />} />
                            </Routes>
                    </BrowserRouter> */}
                    {/* <Routes>
                        <Route path="../ServiceDetails" element={<ServiceDetails />} />
                    </Routes> */}
                    
                </div>

                
                

                <div className='aboutdel_box'>
                    <div className='service_icon'>{faBullseyeIcon}</div>
                    <h4 className='aboutLines'>Proactive</h4>
                    <div className='service_content'>
                        <h5>Tailored Strategies, Tangible Results</h5>
                        <p>Our approach is meticulously crafted to meet the unique needs of each client. By focusing on reducing Total Cost of Ownership (TCO) and maximizing business benefits, we deliver a comprehensive, all-encompassing IT solution. Our strategic alliances with global leaders in technology empower us to provide top-tier services. We are not just a service provider; we are a partner in your compliance and risk initiatives, offering a road map to success, ideation workshops, and robust project management.</p>
                        <br/>
                        
                    </div>
                </div>

                <div className='aboutdel_box last_one'>
                    <div className='service_icon green_icon'>{faHandshakeIcon}</div>
                    <h4 className='aboutLines'>Strategic</h4>
                    <br/>
                    <div className='service_content'>
                        <h5>Your Partner in Comprehensive IT Solutions</h5>
                        <p>SiBr stands as a beacon of IT excellence, committed to delivering outstanding results. Our solutions are designed to be a one-stop-shop for all your IT needs, from strategic planning to implementation. Whether it's enhancing your IT infrastructure, cybersecurity, data management, or bespoke software solutions, SiBr is your trusted partner in navigating the complex world of IT. Join us in paving the way towards a technologically empowered future.</p>
                        <br/>
                    </div>
                </div>

                

                

               
                {/* <div className='btn_wrapper'>
                        <button type='submit' className='btn'>
                            Explore More
                        </button>
                </div> */}
            </div>
            </div>
    </section>
    <br/>
    <Reach />


    
    </>
  )
}
