import React from 'react'
import BlogItem from './BlogItem'
import './BlogList.css'

export default function BlogList({blogs}) {
  return (
    <div className='blogList_wrapper'>
        {blogs.map((blog)=>(
        // <BlogItem blog={blog} key={blog.id}/>
        <BlogItem blog={blog}/>
        ))}
      
    </div>
  )
}
