import React, {useState, useEffect} from 'react'
import './PartnerGrids.css';
// import {Grid, Card, CardContent, Typography} from "@mui/material"
// import Container from '@mui/material/Container'
// import { CardActionArea } from '@mui/material';
// import CardMedia from '@mui/material/CardMedia';
import AnglesDown from '../../assets/about/angles_down3.png';

import Apple from '../../assets/partners/apple3.jpg'
import Cisco from '../../assets/partners/cisco6.jpg'
import Dell from '../../assets/partners/dell.jpg'
import GoogleWS from '../../assets/partners/googlews.jpg'
import HP from '../../assets/partners/hp.jpg'
import Lenovo from '../../assets/partners/lenovo.jpg'
import Logitech from '../../assets/partners/logitech.jpg'
import MS from '../../assets/partners/ms.jpg'
import Neat from '../../assets/partners/neat.jpg'
import Poly from '../../assets/partners/poly.jpg'
import Samsung from '../../assets/partners/samsung.jpg'
import Targus from '../../assets/partners/targus.jpg'
import Zoom from '../../assets/partners/zoom.jpg'
import Motadata from '../../assets/partners/motadata.jpg'
import Blanco from '../../assets/partners/blanco.jpg'
import Rubrik from '../../assets/partners/rubrik.jpg'
import Fortra from '../../assets/partners/fortra.jpg'
import Darkrace from '../../assets/partners/darkrace.jpg'
import Fortinet from '../../assets/partners/fortinet.jpg'
import Opentext from '../../assets/partners/opentext.jpg'
import Tenable from '../../assets/partners/tenable.jpg'
import Knowbe4 from '../../assets/partners/knowbe4.jpg'
import { Helmet } from 'react-helmet-async';

import Reach from '../ReachOut/Reach';


export default function PartnerGrids() {
    

    /*Smaple */

    const listenToScrolls = () => {
        let heightToHidden = 5;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    
        (windowScroll > heightToHidden) ? setIsVisible(false) : setIsVisible(true);
    }
    
    useEffect(()=>{
        window.addEventListener("scroll", listenToScrolls)
    })
    
    const [isVisible, setIsVisible] = useState(true);

    /*Smaple */
  return (
    
    <>

    <Helmet>
        <title>SIBR Partners</title>
      
        <meta name="description" content="At SIBR, we collaborates with top-tier global IT and engineering firms to deliver sophisticated digital solutions, inventive products, and comprehensive end-to-end quality."/>
        <link rel='canonical' href='/partners'/>
    </Helmet>

<section id="servicePartdetails">
        
        <div className="servicePart_banner">
           
        </div>

        <div className="container">
            <div className='servicePart_outer'>
          
                <h6 className='servicePart_title'>
                <span>SI BR</span> <br />
                    OUR PARTNERS
                </h6>
               
            </div>
            
            
        </div>

    </section>

    

      <section>
      
      <div className="rows">

      {isVisible && (
        <div className="partner_down_arrow">
            
            <img src={AnglesDown} alt='Down Arrow'></img>
   
        </div>
      )}
    
<div className="column">
    
    
  
<p>SIBR partnerships are founded on shared goals and strategic alignment. We to achieve objectives that may include market expansion, product development, or mutual business growth.</p>
    <img src={Apple}/>
    <img src={Cisco}/>
    <img src={Dell}/>
    <img src={GoogleWS}/>
    <img src={HP}/>
    <img src={Lenovo}/>
    <img src={Logitech}/>
    <img src={MS}/>
    <img src={Neat}/>
    <img src={Poly}/>
    <img src={Samsung}/>
    <img src={Targus}/>
    <img src={Zoom}/>
    <img src={Motadata}/>
    <img src={Blanco}/>
    <img src={Rubrik}/>
    <img src={Fortra}/>
    <img src={Darkrace}/>
    <img src={Fortinet}/>
    <img src={Opentext}/>
    <img src={Tenable}/>
    <img src={Knowbe4}/>

</div>

</div>

      </section>


    
    
    <Reach />

    </>

    


    
  )
}
