import React from 'react'
import './BlogItem.css'
import { Link } from 'react-router-dom'
import Chip from '../OurBlog/BlogChip'
import Reach from '../ReachOut/Reach';


export default function BlogItem({blog:{
    id,
    description,
    title,
    createAt,
    authorName,
    authorAvatar,
    category,
    cover
}}) {
  return (
   
    
    <div className='blog_container'>
      
<div className='blogItem_wrapper'>
  
<a href={`/blog/${id}`}><img className='blogItem_cover' src={cover} alt='cover'/></a>
        <Chip label={category}/>
        <a href={`/blog/${id}`}><h3>{title}</h3></a>
        
        {/* <Link  className='title_text' to={`/blog/${id}`}>{title}</Link> */}
    
        {/* <p className='blogItem_description'>{description}</p> */}
       
        
        <footer>
            
            <div className='blogItem_author'>
                
                {/* <img src={authorAvatar} alt='avatar'/> */}
                <div>
                    <h6>{createAt}</h6>
                    {/* <h6>{authorName}</h6> */}
                    {/* <p>{createAt}</p> */}
                </div>
            </div>
            {/* <Link className='blogItem_link' to={`/blog/${id}`}>
          ➝
        </Link> */}
        </footer>

        
    </div>
    
    </div>
    
  
   
    
  )

}
