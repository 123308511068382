import React from 'react'
import './Blog.css'
import BlogImage from '../../assets/blog/blog.jpg'

export default function Blog() {
  return (
    <>
    <section id='blog'>
        <div className='container'>
            <div className='blog_wrapper'>
                <div className='blog_col'>
                    <h3>WE HELP BUSINESS LAUNCH, GROW AND SUCCESS</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <div className='btn_wrapper'>
                        <a className='btn' href='/'>Get Started</a>
                    </div>
                </div>
                <div className='blog_col'>
                    <div className='blog_img'>
                        <img src={BlogImage} alt="Blog"/>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
    </section>
    
    </>
  )
}
