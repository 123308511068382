import React, {useState} from 'react'
import './BlogGrids.css';
import BlogSearch from './BlogSearch';
import BlogList from './BlogList';
import { blogList } from './data';
import EmptyList from './EmptyList';

export default function BlogGrids() {
    const [blogs, setBlogs] = useState(blogList);
    const [searchkey, setSearchKey] = useState('');

    //Search Submit Func

    const handleSearchSubmit=event=>{
        event.preventDefault();
        handleSearchResults();

    }
    //Search for blogs by category
    const handleSearchResults=()=>{
        const allBlogs = blogList;
        const filteredBlogs = allBlogs.filter(blog=>blog.category.toLowerCase().includes(searchkey.toLowerCase().trim())
        
        );

        setBlogs(filteredBlogs);

    };


    const handleClearSearch=()=>{
        setBlogs(blogList);
        setSearchKey('');
    }


  return (
    <div>
      <header className='home-header'>
        {/* <h2>Inc. This morning</h2>
        <h1>
            <spna>"</spna>Blog <span>"</span>
        </h1>
        <p>
            awesome place to make oneself <br/> productive and entertained through daily updates.
        </p> */}
      </header>
      <BlogSearch
      value={searchkey}
      clearSearch={handleClearSearch}
      formSubmit={handleSearchSubmit}
      handleSearchKey={e=>setSearchKey(e.target.value)} />
      {!blogs.length ? <EmptyList/>:<BlogList blogs={blogs}/>}
    </div>
    
  )
}
