import React,{useState, useEffect} from 'react'
import './ServiceCMS.css';
import Reach from '../ReachOut/Reach'
import CyberManage from '../../assets/service_about/cms2.png'
import CyberSecurityManage from '../../assets/service_about/csm.png'
import AnglesDown from '../../assets/about/angles_down3.png';
import { Helmet } from 'react-helmet-async';
import AppSecurity from '../../assets/service_about/secmonitor_icon.png';
import Threat from '../../assets/service_about/threat_icon.png';
import Deception from '../../assets/service_about/deception_icon.png';
import ThreadIntel from '../../assets/service_about/thread_icon.png';
import vulner from '../../assets/service_about/vulneral_icon.png';
import Secmonitor from '../../assets/service_about/secmonitor2_icon.png';
import Gateway from '../../assets/service_about/gateway_icon.png';
import SecOp from '../../assets/service_about/secoper_icon.png';
import Complaince from '../../assets/service_about/compliance_icon.png';
import Detection from '../../assets/service_about/detection_icon.png';


export default function ServiceCMS() {
    /*Smaple */

    const listenToScrolls = () => {
        let heightToHidden = 5;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    
        (windowScroll > heightToHidden) ? setIsVisible(false) : setIsVisible(true);
    }
    
    useEffect(()=>{
        window.addEventListener("scroll", listenToScrolls)
    })
    
    const [isVisible, setIsVisible] = useState(true);

    /*Smaple */
  return (
    <>
    <Helmet>
        <title>Cybersecurity Managed Services</title>
        {/* <meta name="description" content="Elevate, Innovate, Integrate – Your Complete IT Partner in Dubai"/> */}
        <meta name="description" content="At SIBR, we provide Cybersecurity Managed Services especially in GRC as a service, SOC as a service, Managed Detection and Response, Managed Gateways, Managed Security Events Monitoring, Threat Hunting as a Service & Managed Application Security Services"/>
        <link rel='canonical' href=''/>
    </Helmet>
    
    <section id="serviceCMSSdetails">
        
        <div className="serviceCMSS_banner">
           
        </div>
        

        <div className="container">
            <div className='serviceCMSS_outer'>
          
                <h1 className='serviceCMSS_title'>
                <span>
                    Cybersecurity Managed Services</span> 
                </h1>
               
               
              


                
            </div>
            
        </div>

        

        

    </section>

    <hr className='about_line'/>

    {isVisible && (
        <div className="cms_down_arrow">
            
            <img src={AnglesDown} alt='Alt'></img>
   
        </div>
      )}

    {/* <section id='serviceCMS_container'>
        <div className='container'>
            <div className='serviceCMS_wrapper'>
                <div className='serviceCMS_col'>
                    <p className='title_para'>Cybersecurity Managed Services refer to outsourced solutions that provide proactive monitoring, <br/> detection, and response to cybersecurity threats and incidents.</p>
               
                    <ul>
                    <p><li>GRC-as-a-service</li></p>
                    <br/>
                    <p><li>SOC-as-a-service</li></p>
                    <br/>
                    <p><li>Managed Detection and Response (MDR)</li></p>
                    <br/>
                    <p><li>Managed Gateways</li></p>
                    <br/>
                    <p><li>Managed Security Events Monitoring</li></p>
                    <br/>
                    <p><li>Managed Vulnerability Scanning</li></p>
                    <br/>
                    <p><li>Managed Threat Intelligence</li></p>
                    <br/>
                    <p><li>Managed Deception Services</li></p>
                    <br/>
                    <p><li>Threat Hunting as a Service</li></p>
                    <br/>
                    <p><li>Managed Application Security Services</li></p>
                    </ul>
                    <div className='btn_wrapper cms_get_started'>
                        <a className='btn' href='/contact-us/'>Get Started</a>
                    </div>
                </div>
                <div className='about_col'>
                    <div className='serviceCMS_img'>
                        
                        <img src={CyberSecurityManage} alt="Data Management"/>
                        
                    </div>
                </div>
            </div>
        </div>
    </section> */}

<br/>
    <div className='cms_container'>
    <p className='cms_para_left'>Cybersecurity Managed Services offer organizations comprehensive solutions to protect their digital assets against cyber threats. These services typically involve continuous monitoring, threat detection, incident response, and security operations, delivered by specialized firms or IT service providers.</p>
    </div>
    <div className='cms_timeline'>
        
        <div className='cms_timeline_container cms_right_container'>
            <img src={Complaince} alt=''/>
            <div className='cms_text_box'>
                <h3>GRC-as-a-service</h3>
                <br/>
                <small>We understand the critical role that Governance, Risk, and Compliance (GRC) play in ensuring the security, integrity, and regulatory compliance of organizations' IT environments. To assist organizations in managing their GRC needs effectively, we offer GRC-as-a-Service as part of our comprehensive Cyber Security Managed Servic</small>
                <span className='cms_left_container_arrow'></span>
            </div>
        </div>
        <div className='cms_timeline_container cms_right_container'>
        <img src={SecOp} alt=''/>
            <div className='cms_text_box'>
                <h3>SOC-as-a-service</h3>
                <br/>
                <small>We understand the critical importance of having a robust Security Operations Center (SOC) to defend against the ever-evolving landscape of cyber threats. However, many organizations may lack the resources or expertise to establish and maintain an in-house SOC. To address this challenge, we offer SOC-as-a-Service as part of our comprehensive Cyber Security Managed Services portfolio.</small>
                <span className='cms_right_container_arrow'></span>
            </div>
        </div>
        <div className='cms_timeline_container cms_right_container'>
        <img src={Detection} alt=''/>
            <div className='cms_text_box'>
                <h3>Managed Detection and Response (MDR)</h3>
                <br/>
                <small>We recognize the evolving nature of cyber threats and the critical need for proactive security measures. As part of our Cyber Security Managed Services, we offer Managed Detection and Response (MDR) to provide organizations with comprehensive threat detection, response, and mitigation capabilities. MDR goes beyond traditional security approaches by combining advanced technologies, expert analysis, and continuous monitoring to identify and neutralize cyber threats.</small>
                <span className='cms_left_container_arrow'></span>
            </div>
        </div>
        <div className='cms_timeline_container cms_right_container'>
        <img src={Gateway} alt=''/>
            <div className='cms_text_box'>
                <h3>Managed Gateways</h3>
                <br/>
                <small>We understand that securing network perimeters is crucial in today's cyber threat landscape. To address this need, we offer Managed Gateways as part of our Cyber Security Managed Services portfolio. Managed Gateways are an essential component of network security, providing protection against unauthorized access, malware, and other cyber threats. Our Managed Gateways service includes the deployment, configuration, monitoring, and management of gateway devices such as firewalls, intrusion prevention systems (IPS), and secure web gateways (SWG).</small>
                <span className='cms_right_container_arrow'></span>
            </div>
        </div>
        <div className='cms_timeline_container cms_right_container'>
        <img src={Secmonitor} alt=''/>
            <div className='cms_text_box'>
                <h3>Managed Security Events Monitoring</h3>
                <br/>
                <small>We understand the critical importance of monitoring security events in real-time to detect and respond to potential threats before they escalate into significant security incidents. To assist organizations in proactively managing their cybersecurity posture, we offer Managed Security Events Monitoring as part of our comprehensive Cyber Security Managed Services.</small>
                <span className='cms_left_container_arrow'></span>
            </div>
        </div>
        <div className='cms_timeline_container cms_right_container'>
        <img src={vulner} alt=''/>
            <div className='cms_text_box'>
                <h3>Managed Vulnerability Scanning</h3>
                <br/>
                <small>We recognize the critical importance of regularly assessing and addressing vulnerabilities within organizations' IT environments to prevent security breaches and data compromises. To assist organizations in proactively managing their cybersecurity posture, we offer Managed Vulnerability Scanning as part of our comprehensive Cyber Security Managed Services.</small>
                <span className='cms_right_container_arrow'></span>
            </div>
        </div>
        <div className='cms_timeline_container cms_right_container'>
        <img src={Threat} alt=''/>
            <div className='cms_text_box'>
                <h3>Managed Threat Intelligence</h3>
                <br/>
                <small>We understand that staying ahead of cyber threats requires more than just reactive security measures—it demands proactive intelligence and strategic insights. To empower organizations in safeguarding their digital assets against emerging threats, we offer Managed Threat Intelligence as part of our comprehensive Cyber Security Managed Services.</small>
                <span className='cms_left_container_arrow'></span>
            </div>
        </div>
        <div className='cms_timeline_container cms_right_container'>
        <img src={Deception} alt=''/>
            <div className='cms_text_box'>
                <h3>Managed Deception Services</h3>
                <br/>
                <small>We recognize the increasing sophistication of cyber threats and the need for innovative approaches to cybersecurity. To help organizations defend against advanced attackers and detect insider threats, we offer Managed Deception Services as part of our comprehensive Cyber Security Managed Services portfolio. It involves the deployment of decoy systems, networks, and assets within organizations' IT environments to lure attackers into revealing their presence and intentions. These decoys mimic legitimate systems and data, creating a virtual minefield for attackers to navigate.</small>
                <span className='cms_right_container_arrow'></span>
            </div>
        </div>
        <div className='cms_timeline_container cms_right_container'>
        <img src={ThreadIntel} alt=''/>
            <div className='cms_text_box'>
                <h3>Threat Hunting as a Service</h3>
                <br/>
                <small>We understand that cybersecurity is an ongoing battle against ever-evolving threats. To empower organizations with proactive defense capabilities, we offer Threat Hunting as a Service as part of our comprehensive Cyber Security Managed Services. It involves the continuous and proactive search for potential security threats within organizations' IT environments. It goes beyond traditional security measures by actively seeking out signs of malicious activity that may evade automated detection systems. </small>
                <span className='cms_left_container_arrow'></span>
            </div>
        </div>
        <div className='cms_timeline_container cms_right_container'>
        <img src={AppSecurity} alt=''/>
            <div className='cms_text_box'>
                <h3>Managed Application Security Services</h3>
                <br/>
                <small>We understand that application security is paramount in today's digital landscape, where cyber threats continue to evolve rapidly. To assist organizations in safeguarding their critical applications against potential threats, we offer Managed Application Security Services as part of our comprehensive Cyber Security Managed Services portfolio. It encompasses a range of proactive measures aimed at identifying, mitigating, and managing security risks associated with applications throughout their lifecycle.</small>
                <span className='cms_right_container_arrow'></span>
            </div>
        </div>

        
    </div>

    <div className='cms_container'>
    <h2 className='cms_para_headleft'>What is Cyber Secuirty Managed Services?</h2>
    <p className='cms_para_left'>Cybersecurity Managed Services entail the outsourcing of security functions and responsibilities to specialized firms or IT service providers. These services encompass a comprehensive range of offerings aimed at safeguarding organizations' digital assets, networks, systems, and data from cyber threats. From continuous monitoring and threat detection to incident response, vulnerability management, and compliance adherence, cybersecurity managed services provide organizations with a proactive and effective approach to managing their security posture. By leveraging advanced technologies, threat intelligence, and expert analysis, these services enable organizations to mitigate security risks, detect and respond to security incidents in real-time, and ensure compliance with industry regulations and standards. With cybersecurity threats becoming increasingly sophisticated and prevalent, cybersecurity managed services offer organizations the expertise, resources, and peace of mind needed to protect against evolving cyber threats and safeguard their critical assets.</p>
    <h2 className='cms_para_headleft'>Cyber Secuirty Managed Services At SiBr</h2>
    <p className='cms_para_left'>
In our IT company, we take pride in offering comprehensive Cyber Security Managed Services tailored to the unique needs and challenges of our clients. Our suite of services encompasses a wide range of solutions aimed at proactively safeguarding organizations' digital assets and infrastructure from cyber threats. From continuous monitoring and threat detection to incident response, vulnerability management, and compliance support, we provide end-to-end security solutions to ensure our clients' peace of mind in today's ever-evolving threat landscape.<br/>By partnering with us for Cyber Security Managed Services, organizations gain access to specialized expertise, continuous monitoring, proactive threat detection, and swift incident response, thereby enhancing their security posture and reducing the risk of cyber threats and data breaches. With our tailored solutions and commitment to excellence, we empower our clients to navigate the complex cybersecurity landscape with confidence and resilience.</p>
    </div>


    <Reach />
    
    </>
  )
}
