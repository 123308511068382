import React, {useState, useEffect} from 'react'
import './ServiceAC.css';
import Reach from '../ReachOut/Reach'
import SysAuto from '../../assets/service_about/sysauto.png'
import SystemAuto from '../../assets/service_about/sys_auto.png'
import AnglesDown from '../../assets/about/angles_down3.png';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faSquareCheck, faDiagramNext, faCircleDot, faGlobe, faCircleNotch, faCircle} from '@fortawesome/free-solid-svg-icons';
import configIcon from '../../assets/service_about/config_icon.png';
import alerting from '../../assets/service_about/alerting_icon.png';
import patchManage from '../../assets/service_about/patchmanage_icon.png';
import backupRecover from '../../assets/service_about/backupRecovery_icon.png';
import WorkflowOrch from '../../assets/service_about/workfloworch_icon.png';
import provAuto from '../../assets/service_about/provAuto_icon.png';



export default function ServiceAC() {

    /*Smaple */
    const faCheckDoubleIcon = <FontAwesomeIcon icon={faCheckDouble} />
    const faSquareCheckIcon = <FontAwesomeIcon icon={faSquareCheck} />
    const faDiagramNextIcon = <FontAwesomeIcon icon={faDiagramNext} />
    const faCircleDotIcon = <FontAwesomeIcon icon={faCircleDot} />
    const faGlobeIcon = <FontAwesomeIcon icon={faGlobe} />
    const faCircleNotchicon = <FontAwesomeIcon icon={faCircleNotch} />
    const faCircleicon = <FontAwesomeIcon icon={faCircle} />

    const listenToScrolls = () => {
        let heightToHidden = 5;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    
        (windowScroll > heightToHidden) ? setIsVisible(false) : setIsVisible(true);
    }
    
    useEffect(()=>{
        window.addEventListener("scroll", listenToScrolls)
    })
    
    const [isVisible, setIsVisible] = useState(true);

    /*Smaple */
  return (
    <>


    <Helmet>
        <title>System Automation</title>
      
        <meta name="description" content="At SIBR, we provide services in System Automation especially in design a tailored smart home system, integrate smart devices such as smart lights, ensure compatibility with popular smart home, implement automation rules, integrate voice control systems, deploy Supervisory Control and Data Acquisition (SCADA) systems and integrate video conferencing equipment for virtual meetings"/>
        <link rel='canonical' href=''/>
    </Helmet>
    
    <section id="serviceACCdetails">
        
        <div className="serviceACC_banner">
           
        </div>

        <div className="container">
            <div className='serviceACC_outer'>
          
                <h1 className='serviceACC_title'>
                <span>
                    System Automation</span>
                </h1>

               
               
              
                
            </div>
            
        </div>

        

        

    </section>

    <hr className='about_line'/>

    {isVisible && (
        <div className="ac_down_arrow">
            
            <img src={AnglesDown} alt='Alt'></img>
   
        </div>
      )}

    {/* <section id='serviceAC_container'>
        <div className='container'>
            <div className='serviceAC_wrapper'>
                <div className='serviceAC_col'>
                    <p className='sa_title_para'>At SiBr, we specialize in providing advanced IT System Automation Services to streamline and optimize your organization's IT operations, improve efficiency, and reduce manual effort. With a team of experienced automation engineers and cutting-edge technologies, we offer comprehensive solutions to automate routine tasks, processes, and workflows across your IT infrastructure.</p>
                 

                    <ul>
                    <p><li>{faCircleicon} <span className='tabb'/>   Conduct a comprehensive assessment of the home and understand the client's automation needs.</li></p>
                    <br/>
                    <p><li>{faCircleNotchicon} <span className='tabb'/> Design a tailored smart home system that may include lighting, climate control, security, entertainment, and more.</li></p>
                    <br/>
                    <p><li>{faCircleicon} <span className='tabb'/> Integrate smart devices such as smart lights, thermostats, locks, cameras, and sensors into a centralized control system.</li></p>
                    <br/>
                    <p><li>{faCircleNotchicon}  <span className='tabb'/>  Ensure compatibility with popular smart home platforms for seamless connectivity.</li></p>
                    <br/>
                    <p><li>{faCircleicon}     <span className='tabb'/>   Create customized automation scenes based on user preferences.</li></p>
                    <br/>
                    <p><li>{faCircleNotchicon} <span className='tabb'/> Implement automation rules that trigger actions based on specific events or schedules.</li></p>
                    <br/>
                    <p><li>{faCircleicon}  <span className='tabb'/> Integrate voice control systems like Amazon Alexa or Google Assistant.</li></p>
                    <br/>
                    <p><li>{faCircleNotchicon} <span className='tabb'/> Deploy Supervisory Control and Data Acquisition (SCADA) systems for centralized monitoring and control of industrial processes.</li></p>
                    <br/>
                    <p><li>{faCircleicon}  <span className='tabb'/> Integrate video conferencing equipment for virtual meetings and presentations. Set up cameras, microphones, and conferencing software for seamless communication.</li></p>
                    <br/>
                    <p><li>{faCircleNotchicon} <span className='tabb'/> Provide routine maintenance to ensure the continued functionality of automation systems.</li></p>
                    </ul>
                    <div className='btn_wrapper sa_get_started'>
                        <a className='btn' href='/contact-us/'>Get Started</a>
                    </div>
                </div>
               
            </div>
        </div>
    </section> */}

<br/>
    <div className='ac_container'>
    <p className='ac_para_left'>System automation services in Information and Communication Technology (ICT) offer a suite of solutions designed to streamline operations, enhance efficiency, and reduce manual intervention through the automation of repetitive tasks and workflows. These services leverage advanced technologies, software tools, and scripts to automate various processes involved in provisioning, configuration, monitoring, and maintenance of ICT infrastructure and services.</p>
    </div>
    <div className='ac_timeline'>
        
        <div className='ac_timeline_container ac_right_container'>
            <img src={provAuto} alt=''/>
            <div className='ac_text_box'>
                <h3>Provisioning Automation</h3>
                <br/>
                <small>Automating the provisioning of resources such as servers, storage, and network infrastructure enables rapid deployment of IT services and applications. This includes automated deployment of virtual machines, containers, and cloud instances based on predefined templates or user-defined policies.</small>
                <span className='ac_left_container_arrow'></span>
            </div>
        </div>
        <div className='ac_timeline_container ac_right_container'>
        <img src={configIcon} alt=''/>
            <div className='ac_text_box'>
                <h3>Configuration Management</h3>
                <br/>
                <small>Automating configuration management ensures consistency and accuracy across ICT systems and devices. Configuration automation tools enable the automated deployment and management of configurations, settings, and policies for servers, routers, switches, firewalls, and other network devices.</small>
                <span className='ac_right_container_arrow'></span>
            </div>
        </div>
        <div className='ac_timeline_container ac_right_container'>
        <img src={alerting} alt=''/>
            <div className='ac_text_box'>
                <h3>Monitoring and Alerting Automation</h3>
                <br/>
                <small>Automated monitoring solutions continuously monitor the performance, availability, and health of ICT infrastructure and applications. These systems generate alerts and notifications in real-time for anomalies, performance degradation, and security incidents, enabling proactive response and remediation.</small>
                <span className='ac_left_container_arrow'></span>
            </div>
        </div>
        <div className='ac_timeline_container ac_right_container'>
        <img src={patchManage} alt=''/>
            <div className='ac_text_box'>
                <h3>Patch Management Automation</h3>
                <br/>
                <small>Automating patch management processes ensures timely and consistent application of software patches and updates across ICT systems and applications. Patch management automation solutions automate the detection, assessment, deployment, and validation of patches to minimize security vulnerabilities and enhance system reliability.</small>
                <span className='ac_right_container_arrow'></span>
            </div>
        </div>
        <div className='ac_timeline_container ac_right_container'>
        <img src={backupRecover} alt=''/>
            <div className='ac_text_box'>
                <h3>Backup and Recovery Automation</h3>
                <br/>
                <small>Automating backup and recovery processes streamlines data protection and disaster recovery operations. Backup automation solutions automate the scheduling, execution, and verification of backups, while recovery automation tools enable rapid restoration of data and systems in the event of data loss or system failure.</small>
                <span className='ac_left_container_arrow'></span>
            </div>
        </div>
        <div className='ac_timeline_container ac_right_container'>
        <img src={WorkflowOrch} alt=''/>
            <div className='ac_text_box'>
                <h3>Workflow Orchestration</h3>
                <br/>
                <small>Workflow orchestration platforms enable the automation of complex, multi-step processes involving multiple systems and applications. These platforms provide visual workflows and drag-and-drop interfaces to automate workflows across heterogeneous environments, integrating disparate systems and streamlining business processes.</small>
                <span className='ac_right_container_arrow'></span>
            </div>
        </div>
        

        
    </div>

    <div className='ac_container'>
    <h2 className='ac_para_headleft'>What is System Automation?</h2>
    <p className='ac_para_left'>System automation in Information and Communication Technology (ICT) refers to the process of automating tasks, operations, and workflows within an IT infrastructure to improve efficiency, accuracy, and reliability. It involves the use of software tools, scripts, and technologies to automate repetitive tasks and streamline IT operations. System automation encompasses a wide range of activities, including server provisioning, configuration management, software deployment, patch management, monitoring, backup and recovery, and workflow orchestration. By automating routine tasks and processes, organizations can reduce manual effort, minimize human errors, accelerate service delivery, and enhance overall productivity. Additionally, system automation helps ensure consistency, compliance, and scalability across IT environments, enabling organizations to adapt more quickly to changing business requirements and technological advancements.</p>
    <h2 className='ac_para_headleft'>System Automation At SiBr</h2>
    <p className='ac_para_left'>At SiBr, we offer cutting-edge System Automation Services tailored to streamline and optimize IT operations for businesses of all sizes. Our comprehensive suite of automation solutions is designed to alleviate the burden of manual tasks, enhance efficiency, and bolster productivity across various IT environments. Leveraging state-of-the-art technologies and industry best practices, our team of experts delivers customized automation solutions tailored to meet the unique needs and challenges of each client.<br/>By leveraging our System Automation Services, organizations can realize numerous benefits, including increased operational efficiency, reduced costs, enhanced agility, improved reliability, and strengthened security posture. Our team works closely with clients to assess their automation needs, design tailored solutions, and provide ongoing support and optimization to maximize the value of automation initiatives. With our expertise and commitment to excellence, we enable organizations to thrive in today's dynamic digital landscape by harnessing the power of automation to drive innovation and accelerate business growth.</p>
    </div>


    <Reach />
    
    </>
  )
}
