import React, {useState, useEffect} from 'react'
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Services from './Components/Services/Services';
import Blog from './Components/Blog/Blog';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
import ServiceDetails from './Components/ServiceDetails/ServiceDetails';
import { Routes, Route } from 'react-router-dom';

import { Component } from 'react';
import { Link } from 'react-scroll';
import ContactDetails from './Components/ContactDetails/ContactDetails';
import AllServices from './Components/ServiceDetails/AllServices';
import ServiceDM from './Components/ServiceDetails/ServiceDM';
import ServiceHA from './Components/ServiceDetails/ServiceHA';
import ServicesCAS from './Components/ServiceDetails/ServicesCAS';
import ServiceCS from './Components/ServiceDetails/ServiceCS';
import ServiceCMS from './Components/ServiceDetails/ServiceCMS';
import ServiceSCPS from './Components/ServiceDetails/ServiceSCPS';
import ServiceAC from './Components/ServiceDetails/ServiceAC';
import ServiceSNS from './Components/ServiceDetails/ServiceSNS';
import ServiceSAV from './Components/ServiceDetails/ServiceSAV';
import ServiceSC from './Components/ServiceDetails/ServiceSC';
import ServiceDCD from './Components/ServiceDetails/ServiceDCD';
import PartnerGrids from './Components/Partners/PartnerGrids';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import WhatsappIcon from './assets/contact/whatsapp_icon.png';
import AboutDetails from './Components/About/AboutDetails';
import PageNotFound from './Components/PageNotFound/PageNotFound';
import ServiceAMC from './Components/ServiceDetails/ServiceAMC';
import OurBlog from './Components/OurBlog/OurBlog';
import BlogGrids from './Components/OurBlog/BlogGrids';
import Privacy from './Components/Privacy/Privacy';



// class App extends Component{
//   render() {
//     return(
//       <Router>
//         <div className='App'>
//           <Link to='/'>Home</Link>
//           <Link to='/'>About</Link>
//           <Link to='/'>Services</Link>
//           <Link to='/'>Blog</Link>
//           <Link to='/'>Contact</Link>
//           <Link to='/'>Footer</Link>
//           <Link to='/ServiceDetails'>ServiceDetails</Link>

//           <Routes>
//             <Route exact path="/" element={<ServiceDetails />}></Route>
//           </Routes>
//         </div>
//       </Router>
//     )
//   }
// }

function App() {
  const faWhatsappIcon = <FontAwesomeIcon icon={faWhatsapp} />

  /*Smaple */
  const listenToScrolls = () => {
    let heightToHidden = 50;
    const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;

    (windowScroll > heightToHidden) ? setIsVisible(true) : setIsVisible(false);
}

useEffect(()=>{
    window.addEventListener("scroll", listenToScrolls)
})

const [isVisible, setIsVisible] = useState(false);

  /*Sample */
  
  return(
    <>
      <Navbar />
      <Routes>
        <Route path='' element={<Home />}/>
        <Route path='/home' element={<Home />}/>
        <Route path='/about-us' element={<About />}/>
        <Route path='/solution' element={<Services />}/>
        <Route path='/explore' element={<Blog />}/>
        <Route path='/partners' element={<PartnerGrids />}/>
        <Route path='/contact' element={<Contact />}/>
        <Route path='/contact-us/' element={<ContactDetails />}/>
        <Route path='/solutions/appmodernization' element={<ServiceDetails />}/>
        <Route path='/solutions/' element={<AllServices />}/>
        <Route path='/solutions/data-management-platforms' element={<ServiceDM />}/>
        <Route path='/solutions/hyperautomation' element={<ServiceHA />}/>
        <Route path='/solutions/consulting-and-advisory-services' element={<ServicesCAS />}/>
        <Route path='/solutions/cybersecurity-services' element={<ServiceCS />}/>
        <Route path='/solutions/cybersecurity-managed-services' element={<ServiceCMS />}/>
        <Route path='/solutions/security-infrastructure' element={<ServiceSCPS />}/>
        <Route path='/solutions/system-automation' element={<ServiceAC />}/>
        <Route path='/solutions/intelligent-networking' element={<ServiceSNS />}/>
        <Route path='/solutions/interactive-media-solutions' element={<ServiceSAV />}/>
        <Route path='/solutions/infrastructure-cabling' element={<ServiceSC />}/>
        <Route path='/solutions/data-facility-planning' element={<ServiceDCD />}/>
        <Route path='/solutions/ICT-managed-services' element={<ServiceAMC />}/>
        <Route path='/aboutDetails' element={<AboutDetails />}/>
        <Route path='/blog/:id' element={<OurBlog />}/>
        <Route path='/blogs' element={<BlogGrids />}/>
        <Route path='/privacy' element={<Privacy />}/>
        <Route path='/*' element={<PageNotFound />}/>
      </Routes>

      {isVisible && (
        <div className='wa_iconss'>
        <a href='https://wa.me/971561250429?text=Hello' target='_blank'>
          <img src={WhatsappIcon} alt=''/>
          
        </a>
      </div>
      )}

      


      <Footer />

      
    </>
  )
  // return (
  //   // <div className="App">
  //   //   <header className="App-header">
  //   //     <img src={logo} className="App-logo" alt="logo" />
  //   //     <p>
  //   //       Edit <code>src/App.js</code> and save to reload.
  //   //     </p>
  //   //     <a
  //   //       className="App-link"
  //   //       href="https://reactjs.org"
  //   //       target="_blank"
  //   //       rel="noopener noreferrer"
  //   //     >
  //   //       Learn React
  //   //     </a>
  //   //   </header>
  //   // </div>
  //   <>
   
  //   <div className='App'>
  //     {/*Components*/}

  //       {/* <Navbar />
        
  //       <Home />
  //       <About />
  //       <Services />
  //       <Blog />
  //       <Contact />
  //       <Footer /> */}
      
  //     {/* <BrowserRouter> 
        
     
  //       <Switch>
  //         <Route path="/" element={<Home />} />
  //         <Route path="/Home" element={<Home />} />
  //         <Route path="/Services/" element={<Services />}>

  //           <Route path="ServiceDetails" element={<ServiceDetails/>}/>
  //         </Route>

  //         <Route path="/Blog" element={<Blog />} />
  //         <Route path="/Contact" element={<Contact />} />
  //         <Route path="/Footer" element={<Footer />} />
          
          
  //       </Switch>
  //     </BrowserRouter> */}

  //     <Navbar />
      
  //     <Home />
  //     <About />
  //     <Services />
  //     <Blog />
  //     <Contact />
  //     <Footer />

  //     {/* <BrowserRouter>
  //       <Routes>  
          
  //         <Route path="/ServiceDetails" element={<ServiceDetails />} />
          
          
  //       </Routes>
  //   </BrowserRouter> */}


  //     {/* <BrowserRouter>
  //     <Services />
  //       <Routes>
          
  //         <Route path="/Services" element={<Services />}>
  //           <ServiceDetails />
  //         </Route>
  //       </Routes>
  //   </BrowserRouter> */}

      
  //   </div>

    
  //   </>
  // );
}

export default App;
