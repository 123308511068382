import React from 'react'
import './About.css';
// import AboutImage from '../../assets/about/about_img.jpg'
import AboutImage from '../../assets/about/about1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faChartPie, faTruckFast, faUserClock, faHouseLaptop, faPhone } from '@fortawesome/free-solid-svg-icons';

export default function About() {
    //Icons
    const faBusinessIcon = <FontAwesomeIcon icon={faBusinessTime} />
    const faChartPieIcon = <FontAwesomeIcon icon={faChartPie} />
    const faTruckFastIcon = <FontAwesomeIcon icon={faTruckFast} />
    const faUserClockIcon = <FontAwesomeIcon icon={faUserClock} />
    const faHouseLaptopIcon = <FontAwesomeIcon icon={faHouseLaptop} />
    const faPhoneIcon = <FontAwesomeIcon icon={faPhone} />
  return (
    <>
    <section id='about'>
        <div class="container">
            <div className='title_handling'>
                <h3>Empower, Connect, Innovate</h3>
            </div>
            <div className='about_box_wrapper'>
                <div className='about_box'>
                    <div className='about_icon'>
                        {faBusinessIcon}

                    </div>
                    <div className='about_content'>
                        <h5>Cyber Security</h5>
                        <p className='cyber_text'>Protecting your digital assets is paramount in today's interconnected world. Our cybersecurity services offer comprehensive solutions to safeguard your business from cyber threats</p>
                        
                    </div>
                </div>
                

                <div className='about_box'>
                    <div className='about_icon green_icon'>
                        {faChartPieIcon}

                    </div>
                    <div className='about_content ict'>
                        <h5>ICT</h5>
                        <p className='cyber_text'>Empower your business with our cutting-edge Information Communication Technology (ICT) services. We specialize in delivering tailored solutions that enhance connectivity, streamline operations, and drive innovation.</p>
                        
                    </div>
                </div>

                

                <div className='about_box'>
                    <div className='about_icon blue_icon'>
                        {faTruckFastIcon}

                    </div>
                    <div className='about_content'>
                        <h5>Digital Innovation</h5>
                        <p className='digital_text'>Embark on a transformative journey with our Digital Innovation services. We harness the power of emerging technologies to revolutionize your business landscape.</p>
                        {/* <div className='btn_wrapper digital_more_services'>
                    <a className='btn' href='/contact-us/'>Learn More</a>
                </div> */}
                    </div>
                    
                </div>
                

            </div>
            <div className='btn_wrapper cyber_more_services'>
                    <a className='btn' href='/contact-us/'>Learn More</a>
                </div>

            {/* <div className='about_box_details'>
                <div className='about_col'>
                    <div className='about_image'>
                        <img src={AboutImage} alt='SiBr About' className="about_main"/>
                    </div>
                    <div className='image_info_box'>
                        <h6 className='img_info_title'>GET YOUR PRICE QUOTE TODAY</h6>
                        <p>Lorem Ipsum is simply dummy text of the ,<br />printing and industry. </p>
                        <a href='/'>{faPhoneIcon} <span>1-800-456-1234</span></a>
                    </div>
                </div>
                <div className='about_col more_space'>
                    <h3>Empowering Success Through Digital Excellence</h3>
                    <p>Where Innovation Meets Infrastructure, and Your Vision Fuels Our Mission</p>
                    
                    <div className='grid_info'>
                        <div className='icon'>{faUserClockIcon}</div>
                        <div className='details'>
                            <h4>Seamless Solutions, Smarter Tomorrow</h4>
                            <p>IT services that seamlessly integrate with your goals, propelling you toward a brighter and more efficient future.</p>
                        </div>
                    </div>

                    <div className='grid_info'>
                        <div className='icon green_icon'>{faHouseLaptopIcon}</div>
                        <div className='details'>
                            <h4>Your Challenges, Our Expertise</h4>
                            <p>Conquering complexity with tailored IT solutions, turning challenges into stepping stones for your success story.</p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>

    </section>
    </>
  )
}
