import React from 'react'
import { Button } from 'react-scroll'
import './BlogSearch.css';

export default function BlogSearch({value, handleSearchKey,clearSearch,formSubmit}) {
  return (
    <div className='searchbar_wrapper'>
      <form onChange={formSubmit}>
        <input 
        type="text" 
        onChange={handleSearchKey} 
        placeholder='Search by category' 
        value={value}/>
        
        {value && <span onChange={clearSearch}>X</span>}


        <button>Go</button>
      </form>
    </div>
    

    

   
    

    

    
    
 


    
  )
}
