import React from 'react'
import './Services.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faChartBar, faCopy, faMarker, faUserGear, faCoins } from '@fortawesome/free-solid-svg-icons';
import { Link, Outlet } from 'react-router-dom';
import Route from '../Router/Route';
import ServiceDetails from '../ServiceDetails/ServiceDetails';
import { BrowserRouter, Navigate, Router, Routes, useNavigate } from 'react-router-dom';

export default function Services() {
    //Icons
    const faLaptopCodeIcon = <FontAwesomeIcon icon={faLaptopCode} />
    const faChartBarIcon = <FontAwesomeIcon icon={faChartBar} />
    const faCopyIcon = <FontAwesomeIcon icon={faCopy} />
    const faMarkerIcon = <FontAwesomeIcon icon={faMarker} />
    const faUserGearIcon = <FontAwesomeIcon icon={faUserGear} />
    const faCoinsIcon = <FontAwesomeIcon icon={faCoins} />

    // const navigate = useNavigate();

    // const navigateToServiceDetails = () => {
    //     navigate('../ServiceDetails', {replace: true});
    // }
    
  return (
    <>
    <div id='services'>
        <div className='container'>
            <div className='title_headling'>
                <h3>What services we are offering to our customer</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

            </div>
            <div className='services_wrapper'>
                <div className='service_box'>
                    <div className='service_icon blue_icon'>{faLaptopCodeIcon}</div>
                    <h4 className='number'>01</h4>
                    
                    
                    <div id="hero" className='service_content'>
                        <h5>Cloud Computing</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <br/>
                        {/* <a href="ServiceDetails" exact target="_blank" className='read'>Read More</a> */}
                        <Link to="/ServiceDetails">Read Moree</Link>

                        {/* <Route exact path='./ServiceDetails'
                            element={<ServiceDetails />}>
                        </Route> */}

                        
                        

                            {/* <Link to="./ServiceDetails">Read More</Link> */}
                            {/* <Link to="/ServiceDetails">Read More</Link> */}
                            

                        
                        
                        
                        {/* <BrowserRouter>
                            <div>
                                <Route exact path="/ServiceDetails" component={ServiceDetails} />
                            </div>
                        </BrowserRouter> */}
                        {/* <a href='./ServiceDetails' className='read' onClick={navigateToServiceDetails}>Read More</a> */}
                        {/* <a href={navigateToServiceDetails} className='read'>Read More</a> */}
                        {/* <Link to="../ServiceDetails" className='read'>Read More</Link> */}
                        {/* <a href='./ServiceDetails' className='read'>Read More</a> */}
                        
                    </div>
                    {/* <BrowserRouter>
                            <Routes>
                            
                            <Route path="/ServiceDetails" element={<ServiceDetails />} />
                            </Routes>
                    </BrowserRouter> */}
                    {/* <Routes>
                        <Route path="../ServiceDetails" element={<ServiceDetails />} />
                    </Routes> */}
                    
                </div>

                
                

                <div className='service_box'>
                    <div className='service_icon'>{faChartBarIcon}</div>
                    <h4 className='number'>02</h4>
                    <div className='service_content'>
                        <h5>Business Catalog</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <br/>
                        <a href='/' className='read'>Read More</a>
                    </div>
                </div>

                <div className='service_box'>
                    <div className='service_icon green_icon'>{faCopyIcon}</div>
                    <h4 className='number'>03</h4>
                    <div className='service_content'>
                        <h5>Report Analysis</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <br/>
                        <a href='/' className='read'>Read More</a>
                    </div>
                </div>

                <div className='service_box'>
                    <div className='service_icon'>{faMarkerIcon}</div>
                    <h4 className='number'>04</h4>
                    <div className='service_content'>
                        <h5>Cloud Computing</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <br/>
                        <a href='/' className='read'>Read More</a>
                    </div>
                </div>

                <div className='service_box'>
                    <div className='service_icon green_icon'>{faUserGearIcon}</div>
                    <h4 className='number'>05</h4>
                    <div className='service_content'>
                        <h5>Business Catalog</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <br/>
                        <a href='/' className='read'>Read More</a>
                    </div>
                </div>

                <div className='service_box'>
                    <div className='service_icon blue_icon'>{faCoinsIcon}</div>
                    <h4 className='number'>06</h4>
                    <div className='service_content'>
                        <h5>Report Analysis</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <br/>
                        <a href='/' className='read'>Read More</a>
                    </div>
                </div>
                <div className='btn_wrapper'>
                        {/* <button type='submit' className='btn'>
                            Explore More
                        </button> */}
                        <a className='btn' href='/solutions/'>Explore More</a>
                </div>
            </div>
        </div>
    </div>
    </>
  )

}
