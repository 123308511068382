import React, {useState, useEffect} from 'react';
// import {Link} from 'react-scroll';

import './Navbar.css';
import Logo from '../../assets/logo/SiBr Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Dropdown from '../Dropdown/Dropdown';
import 'remixicon/fonts/remixicon.css'
import { Button } from 'react-scroll';
import NavImg from '../../assets/about/about3.png'
// import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';

export default function Navbar() {



    

    /*Sample */
    function closeNavOnClick(id) {
        $('#'+id+' a').on('mouseup', function (e) {
            $('#'+id).removeClass("show");
        }); 
    }

    // $(document).ready(function() {
    //     $('body').on('mouseup', function (e) {
    //       if (!$(e.target).hasClass("drop-menu")) {
    //         $(".drop-menu").each(function () {
    //           $(this).removeClass("show");
    //         });
    //       }
    //     });
    //   });

    /*Sample Data Starts */
    // document.addEventListener("click", e => {
    //     const isDropDownButton = e.target.matches("[data-dropdown-button]")
    //     if(!isDropDownButton && e.target.closest("[data-dropdown]") != null) return

    //     let currentDropDown
    //     if(isDropDownButton){
    //         currentDropDown = e.target.closest('[data-dropdown]')
    //         currentDropDown.classList.toggle('active')
    //     }

    //     document.querySelectorAll("[data-dropdown].active").forEach(Dropdown => {
    //         if(Dropdown === currentDropDown) return
    //         Dropdown.classList.remove('active')
    //     })
    // })

    /*Sample Data Ends */

  

    //Icons
    const faCaretDownIcon = <FontAwesomeIcon icon={faCaretDown} />

    //Icons
    const faTimesIcon = <FontAwesomeIcon icon={faTimes} />


    //Mobile Menu State
    // const [sidenav, setSidenav] = useState(false);

    //Desktop Fixed Menu
    // const[sticky, setSticky] = useState(false);

    //Mobile Icon
    const menuIcon = <FontAwesomeIcon icon={faBars} />

    

    // SideNav
    // const sidenavShow = () => {
    //     setSidenav(!sidenav);
        

    // }

    


    //Scroll Fixed Navbar
    // useEffect(() => {
    //     const handleScroll = () => {
    //         setSticky(window.scrollY > 20)
    //     }

    //     window.addEventListener('scroll', handleScroll)
    //     return () => window.removeEventListener('scroll', handleScroll);
    // })



  return (
    <>
    <header id="site_header">
        <body>
            
        </body>
        <nav>
            <div className="wrapper">
                
                <div className="logo">
                    <a href="/">
                        <img src={Logo} alt="Si Br Solutions"/>
                    </a>

                </div>
                {/* <div className="navbar_toggler" onClick={sidenavShow}>
                    {menuIcon}

                </div> */}
                {/* <input type="radio" name="slider" id="menu-btn" />
                <input type="radio" name="slider" id="close-btn"></input> */}
                {/* <div className="navbar_toggler" onClick={sidenavShow}>
                    {menuIcon}

                </div> */}

                

                <input type='radio' name='slide' id='menu-btn'/>
                <input type='radio' name='slide' id='cancel-btn' />
                <ul className='nav-links'>
                        <label htmlFor="cancel-btn" className="btn cancel-btn"><i className="fas fa-times">{faTimesIcon}</i></label>
                        
                        <li><Link to='/home' onClick={closeNavOnClick}>Home</Link></li>
                        <li><Link to='/aboutDetails' onClick={closeNavOnClick}>About</Link></li>
                        <li>
                            <Link to='/solutions/' className='desktop-item' onClick={closeNavOnClick}>Solution {faCaretDownIcon}</Link>
                            <input type="checkbox" id="showMega"></input>
                            <label for="showMega" className="mobile-item">Services {faCaretDownIcon}</label>
                            <div className='mega-box'>
                                <div className='content'>
                                    <div className='row'>
                                        <img src={NavImg} alt=''></img>
                                    </div>
                                    <div className='row'>
                                        <header>Cyber Security</header>
                                        <ul className='mega-links'>
                                        <li><Link to='/solutions/consulting-and-advisory-services' onClick={closeNavOnClick}>Consulting & Advisory Services</Link></li>
                                        <li><Link to='/solutions/cybersecurity-services' onClick={closeNavOnClick}>Cybersecurity Services</Link></li>
                                        <li><Link to='/solutions/cybersecurity-managed-services' onClick={closeNavOnClick}>Cybersecurity Managed Services</Link></li>
                               
                                        </ul>
                                    </div>
                                    <div className='row'>
                                        <header>ICT</header>
                                        <ul className='mega-links'>
                                        <li><Link to='/solutions/security-infrastructure' onClick={closeNavOnClick}>Security Infrastructure</Link></li>
                                        <li><Link to='/solutions/system-automation' onClick={closeNavOnClick}>System Automation</Link></li>
                                        <li><Link to='/solutions/intelligent-networking' onClick={closeNavOnClick}>Intelligent Networking</Link></li>
                                        <li><Link to='/solutions/interactive-media-solutions' onClick={closeNavOnClick}>Interactive Media Solutions</Link></li>
                                        <li><Link to='/solutions/infrastructure-cabling' onClick={closeNavOnClick}>Infrastructure Cabling</Link></li>
                                        <li><Link to='/solutions/data-facility-planning' onClick={closeNavOnClick}>Data Facility Planning</Link></li>
                                        <li><Link to='/solutions/ICT-managed-services' onClick={closeNavOnClick}>ICT Managed Services</Link></li>
                                        {/* Smart City & Physical Security
                                        Automation & Control
                                        Smart Network Solution
                                        Smart Audio Visual
                                        Structured Cabling
                                        Data Center Design And ICT*/}
                                        </ul>
                                    </div>
                                    <div className='row'>
                                        <header>Digital Innovation</header>
                                        <ul className='mega-links'>
                                        <li><Link to='/solutions/appmodernization' onClick={closeNavOnClick}>App Modernization</Link></li>
                                        <li><Link to='/solutions/data-management-platforms' onClick={closeNavOnClick}>Data Management Platforms</Link></li>
                                        <li><Link to='/solutions/hyperautomation' onClick={closeNavOnClick}>Hyperautomaiton</Link></li>
                                        {/* <li><a href=''>Cybersecurity</a></li> */}
                                        </ul>
                                    </div>
                                    
                                    
                                    
                                </div>
                            </div>
                            {/* <ul className='drop-menu'>
                                <li><a href=''>Cybersecurity</a></li>
                                <li><a href=''>Cybersecurity</a></li>
                                <li><a href=''>Cybersecurity</a></li>
                                <li><a href=''>Cybersecurity</a></li>
                            </ul> */}
                        </li>
                        <li><Link to='/blogs' onClick={closeNavOnClick}>Blog</Link></li>
                        <li><Link to='/partners' onClick={closeNavOnClick}>Partners</Link></li>
                        <li><Link to='/contact-us/' onClick={closeNavOnClick}>Contact</Link></li>
                        <li className='breachedd'><Link to='/contact-us/'>Experienced a Breach?</Link></li>
                       
                        {/* <li>
                    
                        <a href='/' className='cnn'>Get Started</a>
                 
                        </li> */}
                    


                </ul>
                    
                



            
                
                <label htmlFor="menu-btn" className="btn menu-btn"><i className="fas fa-bars">{menuIcon}</i></label>

                

            

            </div>

        </nav>
        

    </header>

    {/* <div style={{height: "1000px"}}></div> */}
    </>
    
  )
}
