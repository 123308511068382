import React from 'react'
import './Coverage.css';
import CoverageArea from '../../assets/contact/CoverageMap10.png';

export default function Coverage() {
  return (
    <>
    <section id="coverageSection">

        <div className="coverage_banner">

        </div>
        

        


        

        

    </section>
    </>
  )
}
