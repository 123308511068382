import React from 'react'
import './BlogChip.css'

export default function BlogChip({label}) {
    
  return (
    <p className='chip'>
     {label} 
    </p>
  )
}
