import React, {useState, useEffect} from 'react'
import './ServiceDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faShieldHalved, faChartArea } from '@fortawesome/free-solid-svg-icons';
import ServiceDetailsAbout from './ServiceDetailsAbout';
import Reach from '../ReachOut/Reach';
import AnglesDown from '../../assets/about/angles_down3.png';
import { Helmet } from 'react-helmet-async';



export default function ServiceDetails() {
    //Icons
    const faChartIcon = <FontAwesomeIcon icon={faChartLine} />
    const faShieldIcon = <FontAwesomeIcon icon={faShieldHalved} />
    const faChartAreaIcon = <FontAwesomeIcon icon={faChartArea} />

    /*Smaple */

    const listenToScrolls = () => {
        let heightToHidden = 5;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    
        (windowScroll > heightToHidden) ? setIsVisible(false) : setIsVisible(true);
    }
    
    useEffect(()=>{
        window.addEventListener("scroll", listenToScrolls)
    })
    
    const [isVisible, setIsVisible] = useState(true);

    /*Smaple */
  return (
    
    <>

    <Helmet>
        <title>App Modernization</title>
        {/* <meta name="description" content="Elevate, Innovate, Integrate – Your Complete IT Partner in Dubai"/> */}
        <meta name="description" content="At SIBR, we provide Application Services especially in selecting an optimal location for the data center, design a robust power distribution system, implement efficient cooling solutions, Design cable pathways and implement cable management solutions, design resilient network architecture, implement access control systems, also offsite data replication & backup rotation strategies"/>
        <link rel='canonical' href=''/>
    </Helmet>
    
    <section id="serviceAppdetails">
        
        <div className="serviceapp_banner">

        </div>

        <div className="container">
            <div className='serviceapp_outer'>
          
                <h1 className='serviceapp_title'>
                <span>
                    App Modernization</span>
                </h1>

               
              
                
            </div>
            
        </div>

        

        

    </section>

    <hr className='about_line'/>

    {isVisible && (
        <div className="app_down_arrow">
            
            <img src={AnglesDown} alt='Alt'></img>
   
        </div>
      )}

    <ServiceDetailsAbout />

    <Reach />
    
    </>
  )
}


