import React from 'react'
import './Reach.css'

export default function Reach() {
  return (
    <>
    <section id='reach'>
        <div className='container'>
            <div className='reach_wrapper'>
                <div className='reach_col'>
                    {/* <h3>Integrating Innovative Solutions At Scale <br/>For A Successful Digital Journey</h3> */}
                    {/* <h3>Empower your digital dreams with our IT mastery <br/>where every challenge sparks a solution!</h3> */}
                    <h3>Your Dedicated Partner In Elevating, Innovating & Integerating Cutting Edge Solutions For All Your IT Needs</h3>
                   
                    <div className='reach_btn_wrapper'>
                        <a className='reach_btn' href='/contact-us/'>Consult with a Professional</a>
                    </div>
                </div>
                {/* <div className='reach_col'>
                    <div className='blog_img'>
                    
                    </div>
                </div> */}
            </div>
        </div>
    </section>
    </>
  )
}
