import React, {useState, useEffect} from 'react'
import './ServiceAMC.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faChartBar, faCopy, faMarker, faUserGear, faCoins, faLightbulb, faBullseye, faHandshake, faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { Link, Outlet } from 'react-router-dom';
import Route from '../Router/Route';
import ServiceDetails from '../ServiceDetails/ServiceDetails';
import { BrowserRouter, Navigate, Router, Routes, useNavigate } from 'react-router-dom';
import Reach from '../ReachOut/Reach';
import AnglesDown from '../../assets/about/angles_down3.png';
import MonitorIcon from '../../assets/service_about/monitor_icon.png';
import { Helmet } from 'react-helmet-async';
import { faClockRotateLeft, faScrewdriverWrench, faShieldHalved, faDna } from '@fortawesome/free-solid-svg-icons';

export default function ServiceAMC() {
    const faLaptopCodeIcon = <FontAwesomeIcon icon={faLaptopCode} />
    const faChartBarIcon = <FontAwesomeIcon icon={faChartBar} />
    const faCopyIcon = <FontAwesomeIcon icon={faCopy} />
    const faMarkerIcon = <FontAwesomeIcon icon={faMarker} />
    const faUserGearIcon = <FontAwesomeIcon icon={faUserGear} />
    const faCoinsIcon = <FontAwesomeIcon icon={faCoins} />
    const faLightbulbIcon = <FontAwesomeIcon icon={faLightbulb} />
    const faBullseyeIcon = <FontAwesomeIcon icon={faBullseye} />
    const faHandshakeIcon = <FontAwesomeIcon icon={faHandshake} />
    const faAnglesDownIcon = <FontAwesomeIcon icon={faAnglesDown} />
    const faClockRotateLeftIcon = <FontAwesomeIcon icon={faClockRotateLeft} />
    const faScrewdriverWrenchIcon = <FontAwesomeIcon icon={faScrewdriverWrench} />
    const faShieldHalvedIcon = <FontAwesomeIcon icon={faShieldHalved} />
    const faDnaIcon = <FontAwesomeIcon icon={faDna} />
    /*Smaple */

    const listenToScrolls = () => {
        let heightToHidden = 5;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    
        (windowScroll > heightToHidden) ? setIsVisible(false) : setIsVisible(true);
    }
    
    useEffect(()=>{
        window.addEventListener("scroll", listenToScrolls)
    })
    
    const [isVisible, setIsVisible] = useState(true);

    /*Smaple */
  return (
    <>
    <Helmet>
        <title>Managed IT Services – Proactive IT Management</title>
        {/* <meta name="description" content="Elevate, Innovate, Integrate – Your Complete IT Partner in Dubai"/> */}
        <meta name="description" content="At SIBR, we provide Cybersecurity Managed Services especially in GRC as a service, SOC as a service, Managed Detection and Response, Managed Gateways, Managed Security Events Monitoring, Threat Hunting as a Service & Managed Application Security Services"/>
        <link rel='canonical' href=''/>
    </Helmet>

    <section id="serviceAMCdetails">
        
        <div className="serviceAMC_banner">
           
        </div>

        <div className="container">
            <div className='serviceAMC_outer'>
          
                <h1 className='serviceAM_title'>
                <span>Managed IT Services<br/>Proactive IT Management</span>
                </h1>
               
            </div>

           
            
        </div>

        

        

        

        

        

        

    </section>



    <hr className='about_line'/>

    {isVisible && (
        <div className="down_arroww">
            
            <img src={AnglesDown} alt='Alt'></img>
   
        </div>
      )}
      



    <section>
        <div className='container'>
        <br/>
        <br/>
        <p>In today's fast-paced digital landscape, ensuring the seamless operation of your IT infrastructure is not just about responding to issues as they arise—it's about anticipating and preventing them before they impact your business. 
Our Managed IT Services are designed with a proactive approach to IT management, ensuring your systems are always running at their best, your data is secure, and your business is equipped with the technology it needs to thrive. Here's how we make it happen:
</p>
<br/>
<br/>

        
    <div className='serviceAMC_wrapper'>
                <div className='serviceAMC_box'>
                    <div className='serviceAMC_icon blue_icon'>{faClockRotateLeftIcon}</div>
                    {/* <h4 className='serviceAMC_Lines'>Expertise</h4> */}
                    
                    
                    <div id="hero" className='service_content'>
                        <h5>24/7 Monitoring and Maintenance</h5>
                        <p>We continuously monitor your IT systems to identify and resolve potential issues before they become problems, ensuring maximum uptime and productivity.</p>
                        <br/>
                        
                        
                    </div>
                    
                    
                </div>

                <div className='serviceAMC_box'>
                    <div className='serviceAMC_icon'>{faHandshakeIcon}</div>
                    {/* <h4 className='serviceAMC_Lines'>Proactive</h4> */}
                    <div className='service_content'>
                        <h5>Custom SLA Agreements</h5>
                        <p>Tailored Service Level Agreements (SLAs) define our commitment to you, with guaranteed response times and resolution metrics that align with your business needs.</p>
                        <br/>
                        
                    </div>
                </div>

                <div className='serviceAMC_box'>
                    <div className='serviceAMC_icon'>{faScrewdriverWrenchIcon}</div>
                    {/* <h4 className='serviceAMC_Lines'>Proactive</h4> */}
                    <div className='service_content'>
                        <h5>Annual Maintenance Contracts (AMCs)</h5>
                        <p>Our comprehensive AMCs provide regular, scheduled maintenance of your IT infrastructure, keeping everything in top condition and reducing the risk of unexpected failures.</p>
                        <br/>
                        
                    </div>
                </div>

                <div className='serviceAMC_box'>
                    <div className='serviceAMC_icon'>{faShieldHalvedIcon}</div>
                    {/* <h4 className='serviceAMC_Lines'>Proactive</h4> */}
                    <div className='service_content'>
                        <h5>Cybersecurity Assurance</h5>
                        <p>We employ state-of-the-art security measures to protect your systems from the latest cyber threats, ensuring your data's integrity and confidentiality.</p>
                        <br/>
                        
                    </div>
                </div>

                <div className='serviceAMC_box'>
                    <div className='serviceAMC_icon'>{faBullseyeIcon}</div>
                    {/* <h4 className='serviceAMC_Lines'>Proactive</h4> */}
                    <div className='service_content'>
                        <h5>Strategic IT Consulting</h5>
                        <p>Beyond maintenance and support, we offer expert consulting to help plan your IT strategy, ensuring your technology investments are aligned with your business goals.</p>
                        <br/>
                        
                    </div>
                </div>

                <div className='serviceAMC_box'>
                    <div className='serviceAMC_icon'>{faDnaIcon}</div>
                    {/* <h4 className='serviceAMC_Lines'>Proactive</h4> */}
                    <div className='service_content'>
                        <h5>Scalable Solutions</h5>
                        <p>Our services are designed to grow with your business, offering scalable solutions that can adapt to your changing needs, whether you're expanding your team or your market reach.</p>
                        <br/>
                        
                    </div>
                </div>
    
                
             

            </div>
            <p>By partnering with us for your Managed IT Services, you're not just hiring an IT support team; you're gaining a strategic ally dedicated to the proactive management and optimization of your IT resources, empowering your business to operate more efficiently, securely, and successfully.</p>
            <br/>
            </div>
    </section>
    <br/>
    <Reach />
    </>
  )
}
