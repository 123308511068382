import React, {useState, useEffect} from 'react'
import './ServiceSC.css';
import Reach from '../ReachOut/Reach'
import Cab from '../../assets/service_about/cab.png'
import AnglesDown from '../../assets/about/angles_down3.png';
import InfraCab from '../../assets/service_about/infra_cab.png';
import { Helmet } from 'react-helmet-async';
import CablingSol from '../../assets/service_about/cablingsol_icon.png';
import CablingSystem from '../../assets/service_about/cablingsystem_icon.png';
import CablingInfra from '../../assets/service_about/cablinginfra_icon.png';
import Voip from '../../assets/service_about/voip.png';
import DataTransmission from '../../assets/service_about/datatransmission_icon.png';
import CablingType from '../../assets/service_about/CablingType.png';
import Reliability from '../../assets/service_about/reliability_icon.png';
import Documentation from '../../assets/service_about/documentation_icon.png';
import Networking from '../../assets/service_about/networking_icon.png';



export default function ServiceSC() {
    /*Smaple */

    const listenToScrolls = () => {
        let heightToHidden = 5;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    
        (windowScroll > heightToHidden) ? setIsVisible(false) : setIsVisible(true);
    }
    
    useEffect(()=>{
        window.addEventListener("scroll", listenToScrolls)
    })
    
    const [isVisible, setIsVisible] = useState(true);


    /*Smaple */
  return (
    <>
    <Helmet>
        <title>Infrastructure Cabling</title>
        {/* <meta name="description" content="Elevate, Innovate, Integrate – Your Complete IT Partner in Dubai"/> */}
        <meta name="description" content="At SIBR, we provide Infrastructure Cabling Solution especially in develop a comprehensive structured cabling system, design a unified cabling infrastructure, integrate voice over IP (VoIP) systems, install fiber optic cables, single-mode or multi-mode fibers & perform tests for parameters such as attenuation"/>
        <link rel='canonical' href=''/>
    </Helmet>
    
    <section id="serviceSCVdetails">
        
        <div className="serviceSCV_banner">
           
        </div>

        <div className="container">
            <div className='serviceSCV_outer'>
          
                <h1 className='serviceSCV_title'>
                <span>
                    Infrastructure Cabling</span>
                </h1>

               
            </div>

            
        </div>

        

        

    </section>

    <hr className='about_line'/>

    {isVisible && (
        <div className="scc_down_arrow">
            
            <img src={AnglesDown} alt='Alt'></img>
   
        </div>
      )}

    {/* <section id='serviceSC_container'>
        <div className='container'>
            <div className='serviceSC_wrapper'>
                <div className='serviceSC_col'>
                    <p className='icc_title_para'>At SiBr, we provide comprehensive connectivity solutions for modern businesses, including structured cabling, network infrastructure, and data center connectivity. With experienced technicians and top-tier equipment, we offer end-to-end solutions to support your connectivity needs.</p>
                    
                    <ul>
                    <p><li>Engage in discussions with the client to understand their present and future networking needs, including data, voice, and multimedia requirements.</li></p>
                    <br/>
                    <p><li>Develop a comprehensive structured cabling system design based on industry standards and the client's specific needs.</li></p>
                    <br/>
                    <p><li>Select appropriate cable types, such as Category 6 (Cat6) or Category 6a (Cat6a) for copper-based systems or fiber optic cables for high-speed and long-distance applications.</li></p>
                    <br/>
                    <p><li>Design a unified cabling infrastructure to support both voice and data applications.</li></p>
                    <br/>
                    <p><li>Integrate voice over IP (VoIP) systems seamlessly into the structured cabling network.</li></p>
                    <br/>
                    <p><li>Install fiber optic cables for high-speed data transmission over longer distances.</li></p>
                    <br/>
                    <p><li>Choose single-mode or multi-mode fibers based on the specific requirements of the organization.</li></p>
                    <br/>
                    
                    <p><li>Perform tests for parameters such as attenuation, crosstalk, and signal integrity to ensure compliance with industry standards.</li></p>
                    <br/>
                    <p><li>Provide comprehensive documentation and certification reports for the structured cabling system.</li></p>
                    </ul>
                    <div className='btn_wrapper icc_get_started'>
                        <a className='btn' href='/contact-us/'>Get Started</a>
                    </div>
                </div>
                <div className='about_col'>
                    <div className='serviceSC_img'>
                        <img src={InfraCab} alt="Data Management"/>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

<br/>
    <div className='sc_container'>
    <p className='sc_para_left'>At SiBr, we specialize in providing comprehensive Infrastructure Cabling services tailored to the specific needs of our clients' Information and Communication Technology (ICT) environments. Our offerings encompass the entire spectrum of cabling solutions, from design and planning to installation, testing, and maintenance. We meticulously assess our clients' infrastructure requirements, taking into account factors such as building layout, network topology, and technology needs. With expertise in a wide range of cable types, including twisted pair copper cables and fiber optic cables, we ensure reliable and high-performance connectivity for data, voice, and multimedia applications. Our certified technicians and installers execute cabling installations with precision and attention to detail, adhering to industry standards and best practices. We conduct thorough testing and certification of the cabling infrastructure to validate performance and compliance, providing clients with detailed documentation and certification reports for reference and compliance purposes. With a commitment to quality and customer satisfaction, our Infrastructure Cabling services enable organizations to establish robust and future-proof network infrastructures that support their business objectives and technological advancements.</p>
    </div>
    <div className='sc_timeline'>
        
        <div className='sc_timeline_container sc_right_container'>
            <img src={Networking} alt=''/>
            <div className='sc_text_box'>
                <h3>Understanding Client Networking Needs</h3>
                <br/>
                <small>Engaging in discussions with the client to understand their present and future networking needs is a critical step in delivering tailored solutions that align with their objectives. This process involves comprehensive dialogue to gather insights into various aspects of their networking requirements, encompassing data, voice, and multimedia needs.</small>
                <span className='sc_left_container_arrow'></span>
            </div>
        </div>
        <div className='sc_timeline_container sc_right_container'>
        <img src={CablingSol} alt=''/>
            <div className='sc_text_box'>
                <h3>Creating Tailored Structured Cabling Solutions for Reliable Network Connectivity</h3>
                <br/>
                <small>Developing a comprehensive structured cabling system design is essential for ensuring reliable and efficient network connectivity that meets the client's current and future needs. Our approach involves adhering to industry standards and leveraging our expertise to create a customized cabling infrastructure tailored to the client's requirements.</small>
                <span className='sc_right_container_arrow'></span>
            </div>
        </div>
        <div className='sc_timeline_container sc_right_container'>
        <img src={CablingSystem} alt=''/>
            <div className='sc_text_box'>
                <h3>Choosing the Right Cable Types for Structured Cabling Systems</h3>
                <br/>
                <small>Selecting appropriate cable types is crucial for ensuring optimal performance and reliability in structured cabling systems. Depending on the specific requirements of the client's network, we carefully choose between copper-based or fiber optic cables, considering factors such as data transmission speed, distance, and environmental conditions.</small>
                <span className='sc_left_container_arrow'></span>
            </div>
        </div>
        <div className='sc_timeline_container sc_right_container'>
        <img src={CablingInfra} alt=''/>
            <div className='sc_text_box'>
                <h3>Unified Cabling Infrastructure Design for Voice and Data Applications</h3>
                <br/>
                <small>Designing a unified cabling infrastructure to support both voice and data applications is essential for maximizing efficiency and reducing costs in modern network environments. Our approach involves carefully planning and implementing a structured cabling system that seamlessly accommodates the requirements of both voice and data services.</small>
                <span className='sc_right_container_arrow'></span>
            </div>
        </div>
        <div className='sc_timeline_container sc_right_container'>
        <img src={Voip} alt=''/>
            <div className='sc_text_box'>
                <h3>Seamless Integration of Voice over IP (VoIP) Systems into Structured Cabling Networks</h3>
                <br/>
                <small>Integrating voice over IP (VoIP) systems seamlessly into the structured cabling network is crucial for modern organizations looking to leverage advanced communication technologies efficiently. Our approach involves careful planning and implementation to ensure a smooth integration process that maximizes the benefits of VoIP while minimizing disruptions to existing network infrastructure.</small>
                <span className='sc_left_container_arrow'></span>
            </div>
        </div>
        <div className='sc_timeline_container sc_right_container'>
        <img src={DataTransmission} alt=''/>
            <div className='sc_text_box'>
                <h3>Ensuring High-Speed Data Transmission</h3>
                <br/>
                <small>Installing fiber optic cables is essential for achieving high-speed data transmission over longer distances, making it a critical component of modern network infrastructures. Our approach to fiber optic installation involves meticulous planning, precise implementation, and rigorous testing to ensure optimal performance and reliability.</small>
                <span className='sc_right_container_arrow'></span>
            </div>
        </div>
        <div className='sc_timeline_container sc_right_container'>
        <img src={CablingType} alt=''/>
            <div className='sc_text_box'>
                <h3>Considerations and Recommendations</h3>
                <br/>
                <small>When deciding between single-mode and multi-mode fibers, we consider factors such as the distance of transmission, bandwidth requirements, budget constraints, and future scalability. Single-mode fibers are preferred for long-haul, high-bandwidth applications, whereas multi-mode fibers are suitable for shorter distances and cost-sensitive projects.</small>
                <span className='sc_left_container_arrow'></span>
            </div>
        </div>
        
        <div className='sc_timeline_container sc_right_container'>
        <img src={Reliability} alt=''/>
            <div className='sc_text_box'>
                <h3>Ensuring Fiber Optic Network Reliability and Performance Through Testing</h3>
                <br/>
                <small>Performing tests for parameters such as attenuation, crosstalk, and signal integrity is essential to ensure the reliability and performance of a fiber optic network. These tests help verify that the installed infrastructure meets industry standards and is capable of delivering the expected level of performance. Our approach involves conducting thorough testing procedures to assess various aspects of the network's performance and identify any potential issues that may affect its functionality.</small>
                <span className='sc_left_container_arrow'></span>
            </div>
        </div>
        <div className='sc_timeline_container sc_right_container'>
        <img src={Documentation} alt=''/>
            <div className='sc_text_box'>
                <h3>Ensuring Transparency and Compliance Through Documentation and Certification</h3>
                <br/>
                <small>Providing comprehensive documentation and certification reports for the structured cabling system is a crucial aspect of ensuring transparency, accountability, and compliance with industry standards. Our approach involves meticulously documenting every aspect of the cabling installation process and compiling detailed certification reports to validate the integrity and performance of the network infrastructure.</small>
                <span className='sc_right_container_arrow'></span>
            </div>
        </div>
       

        
    </div>

    <div className='sc_container'>
    <h2 className='sc_para_headleft'>What is Infrastructure Cabling?</h2>
    <p className='sc_para_left'>
Infrastructure cabling, also known as structured cabling, refers to the network of cables and associated hardware used to establish a reliable and efficient communication infrastructure within a building or facility. It serves as the backbone for various information and communication technology (ICT) systems, including data networks, voice communication, audio-visual systems, security systems, and more.<br/>The primary goal of infrastructure cabling is to provide a standardized and organized framework for connecting devices and equipment across different areas of a building or campus. This includes running cables for data transmission, telephone lines, video signals, and power distribution, among others. By establishing a structured cabling system, organizations can create a scalable and future-proof infrastructure that supports current and emerging technology needs.</p>
    <h2 className='sc_para_headleft'>Infrastructure Cabling At SiBr</h2>
    <p className='sc_para_left'>At SiBr, we offer comprehensive Infrastructure Cabling services as part of our ICT (Information and Communication Technology) solutions portfolio. Our Infrastructure Cabling services encompass the design, installation, and maintenance of structured cabling systems tailored to the specific needs of our clients.<br/>Overall, our Infrastructure Cabling services are designed to help organizations establish robust, scalable, and future-proof network infrastructures that support their current and future technology needs. With our expertise and commitment to quality, we deliver reliable and efficient cabling solutions that enable our clients to optimize their ICT environments and achieve their business objectives.</p>
    </div>


    <Reach />
    
    </>
  )
}
